import { AppService } from "./../../@core/service/App.Service";
import { Injectable, Component } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LovService extends AppService<any> {
  async getelementTypes() {
    return await this.get("List", ["SBTY"]);
  }

  constructor() {
    super("mst_lov");
  }

  async getUsers() {
    const result = await this.get("List", ["USERS"]);
    return result;
  }
  async getPercentPolicy() {
    const result = await this.get("List", ["NDWR"]);
    return result;
  }

  async getHifzSequence() {
    const result = await this.get("List", ["HFZSQ"]);
    return result;
  }

  async getQuranTypes() {
    const result = await this.get("List", ["QPNHG"]);
    return result;
  }

  async getPageLines() {
    const result = await this.get("List", ["QPL"]);
    return result;
  }

  async getProjectCategory() {
    const result = await this.get("List", ["RPCAT"]);
    return result;
  }
  async getLandlordCategory() {
    const result = await this.get("List", ["LLORD"]);
    return result;
  }

  async getFloor() {
    const result = await this.get("List", ["RMFL"]);
    return result;
  }
  async getBookStatus() {
    const result = await this.get("List", ["BKST"]);
    return result;
  }
  async getTypeNSRNS() {
    const result = await this.get("List", ["NSRNS"]);
    return result;
  }
  async getYearBasedon() {
    const result = await this.get("List", ["YRO"]);
    return result;
  }

  async getexpensivetype() {
    const result = await this.get("List", ["EXPH"]);
    return result;
  }
  async getVtypeLov() {
    const result = await this.get("List", ["CNVTP"]);
    return result;
  }
  async getApplicableOn() {
    const result = await this.get("List", ["EMPI"]);
    return result;
  }
  async getEmpPaymentMode() {
    const result = await this.get("List", ["EMPPM"]);
    return result;
  }
  async getTFTY() {
    const result = await this.get("List", ["TFTY"]);
    return result;
  }
  async getPayrollImpact() {
    const result = await this.get("List", ["ERTYP"]);
    return result;
  }
  async getSRCCAt() {
    const result = await this.get("List", ["SRCT"]);
    return result;
  }
  async getPayType() {
    const result = await this.get("List", ["EMPPT"]);
    return result;
  }
  async getRATG() {
    const result = await this.get("List", ["RATG"]);
    return result;
  }
  async getFSTF() {
    const result = await this.get("List", ["FSTF"]);
    return result;
  }
  async getstagetype() {
    const result = await this.get("List", ["STAG"]);
    return result;
  }
  async getpaid() {
    const result = await this.get("List", ["FSTS"]);
    return result;
  }

  async getCourierCompany() {
    const result = await this.get("List", ["COURI"]);
    return result;
  }

  async getActivity() {
    const result = await this.get("List", ["ACTT"]);
    return result;
  }

  async getGender() {
    const result = await this.get("List", ["GEND"]);
    return result;
  }

  async getjobStatus() {
    const result = await this.get("List", ["JBSTS"]);
    return result;
  }

  async getComplaintType() {
    const result = await this.get("List", ["CTYPE"]);
    return result;
  }

  async getComplainantType() {
    const result = await this.get("List", ["PTYPE"]);
    return result;
  }

  async getBloodGroup() {
    const result = await this.get("List", ["BLOOD"]);
    return result;
  }

  async getPrType() {
    const result = await this.get("List", ["STDSP"]);
    return result;
  }
  async getShift() {
    const result = await this.get("List", ["SHIFT"]);
    return result;
  }
  async getperiodperday() {
    const result = await this.get("List", ["PPD"]);
    return result;
  }

  async getPeriodUnit() {
    const result = await this.get("List", ["PUNIT"]);
    return result;
  }

  async getLocationType() {
    const result = await this.get("List", ["LOCTP"]);
    return result;
  }

  async getTrip() {
    const result = await this.get("List", ["TRIP"]);
    return result;
  }

  async getTripCategory() {
    const result = await this.get("List", ["TRIPC"]);
    return result;
  }

  async getFeeOccurance() {
    const result = await this.get("List", ["FOCCU"]);
    return result;
  }

  async getSessionStatus() {
    const result = await this.get("List", ["NSESS"]);
    return result;
  }

  async getFeeNature() {
    const result = await this.get("List", ["FNATU"]);
    return result;
  }

  async getVehicleCategory() {
    const result = await this.get("List", ["VCAT"]);
    return result;
  }
  async getEmployeeType() {
    const result = await this.get("List", ["EMPTP"]);
    return result;
  }

  async getAuthor() {
    const result = await this.get("List", ["AUTHT"]);
    return result;
  }

  async getAuthors() {
    const result = await this.get("List", ["AUTHR"]);
    return result;
  }
  async getPositionType() {
    const result = await this.get("List", ["PSTYP"]);
    return result;
  }
  async getLevel() {
    const result = await this.get("List", ["LEVEL"]);
    return result;
  }
  async getPeriodunit() {
    const result = await this.get("List", ["PUNIT"]);
    return result;
  }
  async getCardType() {
    const result = await this.get("List", ["CRDTP"]);
    return result;
  }
  async getBusinessUnit() {
    const result = await this.get("List", ["BSNAT"]);
    return result;
  }
  async getDocumentType() {
    const result = await this.get("List", ["EMPDC"]);
    return result;
  }
  async getContacttype() {
    const result = await this.get("List", ["CNTYP"]);
    return result;
  }
  async getCallType() {
    const result = await this.get("List", ["CALLT"]);
    return result;
  }
  async getCallMedium() {
    const result = await this.get("List", ["CALLM"]);
    return result;
  }
  async getFinetype() {
    const result = await this.get("List", ["FNTYP"]);
    return result;
  }
  async getBaseon() {
    const result = await this.get("List", ["BASON"]);
    return result;
  }

  async getBasedAddmission() {
    const result = await this.get("List", ["BODT"]);
    return result;
  }
  async getCondition() {
    const result = await this.get("List", ["RCOND"]);
    return result;
  }
  async getCalcOn() {
    const result = await this.get("List", ["EPCON"]);
    return result;
  }
  async getEmpCalcOn() {
    const result = await this.get("CalcOn");
    return result;
  }
  async getEmpRateb() {
    const result = await this.get("Rateb");
    return result;
  }
  async getLimiper() {
    const result = await this.get("Mothly");
    return result;
  }
  async getIncrementtype() {
    const result = await this.get("List", ["FNINC"]);
    return result;
  }

  async getEmployeeCategory() {
    const result = await this.get("List", ["ECAT"]);
    return result;
  }

  async getGrouptype() {
    const result = await this.get("List", ["FNATU"]);
    return result;
  }

  async getCategorytype() {
    const result = await this.get("List", ["FNES"]);
    return result;
  }

  async getRateBasis() {
    const result = await this.get("List", ["RateB"]);
    return result;
  }

  getYear(start: any, end: any): any[] {
    var item = [];

    for (var i = start; i <= end; i++) {
      item.push({ Id: i });
    }

    return item;
  }

  async getVehicleTrip(vehicleid: any, tripcatid: any) {
    const result = await this.get("VehicleTrip", [vehicleid, tripcatid]);
    return result;
  }

  async getFeeFor() {
    const result = await this.get("List", ["FFOR"]);
    return result;
  }

  async getCampustype() {
    const result = await this.get("List", ["CPTY"]);
    return result;
  }

  async getCampType() {
    const result = await this.get("List", ["CMST"]);
    return result;
  }

  async getWeekdays() {
    const result = await this.get("List", ["WDAY"]);
    return result;
  }
  async getApplyon() {
    const result = await this.get("List", ["APLYH"]);
    return result;
  }
  async getResponse() {
    const result = await this.get("List", ["RSPTP"]);
    return result;
  }
  async getStatus() {
    const result = await this.get("List", ["STATS"]);
    return result;
  }

  async getComplaintStatus() {
    const result = await this.get("List", ["CMSTS"]);
    return result;
  }

  async GetCertificateFor() {
    const result = await this.get("List", ["CFFOR"]);
    return result;
  }

  async getmaritialStatus() {
    const result = await this.get("List", ["MRSTS"]);
    return result;
  }
  async getActiveInActive() {
    const result = await this.get("List", ["AACT"]);
    return result;
  }

  async getStatusType() {
    const result = await this.get("List", ["SType"]);
    return result;
  }

  async getdocumentsType() {
    const result = await this.get("List", ["DCTYP"]);
    return result;
  }
  async getLimitPeriod() {
    const result = await this.get("List", ["MAAD"]);
    return result;
  }
  async getCalculateOn() {
    const result = await this.get("List", ["CALC"]);
    return result;
  }

  async getLicenseType() {
    const result = await this.get("List", ["LICTP"]);
    return result;
  }

  async getMarital() {
    const result = await this.get("List", ["MRSTS"]);
    return result;
  }

  async getLicenseCategory() {
    const result = await this.get("List", ["LICCT"]);
    return result;
  }

  async getMembertype() {
    const result = await this.get("List", ["MEMTP"]);
    return result;
  }

  async getAddresstype() {
    const result = await this.get("List", ["ADDTP"]);
    return result;
  }
  async getBaseOn() {
    const result = await this.get("List", ["BASON"]);
    return result;
  }
  async getDisCat() {
    const result = await this.get("List", ["DISCT"]);
    return result;
  }
  async getDisTyp() {
    const result = await this.get("List", ["DISTP"]);
    return result;
  }
  async getMeet() {
    const result = await this.get("List", ["PTMWT"]);
    return result;
  }
  async getPolicytype() {
    const result = await this.get("List", ["ATPOL"]);
    return result;
  }
  async getPenaltytype() {
    const result = await this.get("List", ["PENTP"]);
    return result;
  }
  async getCalculation() {
    const result = await this.get("List", ["AMDB"]);
    return result;
  }
  async getAttendancetype() {
    const result = await this.get("List", ["ATNTP"]);
    return result;
  }
  async getLeaveunit() {
    const result = await this.get("List", ["LVUNT"]);
    return result;
  }

  async getLeaveStatus() {
    const result = await this.get("List", ["LVSTS"]);
    return result;
  }

  async getEntitleon() {
    const result = await this.get("List", ["LVTIT"]);
    return result;
  }
  async getAccrualunit() {
    const result = await this.get("List", ["ACUNT"]);
    return result;
  }

  async getgenratedChallan() {
    const result = await this.get("List", ["CGNRT"]);
    return result;
  }

  async getSchedulefor() {
    const result = await this.get("List", ["SCHFR"]);
    return result;
  }
  async getResult() {
    const result = await this.get("List", ["RESLT"]);
    return result;
  }

  async getComplainertype() {
    const result = await this.get("List", ["CMPTP"]);
    return result;
  }

  async getpayMOde() {
    const result = await this.get("List", ["PAYMD"]);
    return result;
  }
  async getinterviewFor() {
    const result = await this.get("List", ["INFR"]);
    return result;
  }

  async getShiftType() {
    const result = await this.get("List", ["SFTT"]);
    return result;
  }
  async getScheduletype() {
    const result = await this.get("List", ["SCTY"]);
    return result;
  }

  async getMessageType() {
    const result = await this.get("List", ["MSGTP"]);
    return result;
  }

  async getresponstype() {
    const result = await this.get("List", ["MSGR"]);
    return result;
  }

  async getSendTo() {
    const result = await this.get("List", ["SNTTO"]);
    return result;
  }

  async getQuestionCategory() {
    const result = await this.get("List", ["QCAT"]);
    return result;
  }

  async getInputType() {
    const result = await this.get("List", ["ANSIN"]);
    return result;
  }

  async getAnswerGroup() {
    const result = await this.get("List", ["ANSGP"]);
    return result;
  }

  async getTerm() {
    const result = await this.get("List", ["TERM"]);
    return result;
  }

  async getQuestionView() {
    const result = await this.get("List", ["QVIEW"]);
    return result;
  }

  async getquestionfor() {
    const result = await this.get("List", ["QFOR"]);
    return result;
  }

  async getQuestionBaseOn() {
    const result = await this.get("List", ["QBASE"]);
    return result;
  }

  async getQuestionSequence() {
    const result = await this.get("List", ["QSEQ"]);
    return result;
  }

  async getQuestionnaireStatus() {
    const result = await this.get("List", ["ASSTS"]);
    return result;
  }
  async getQuestionnaireStatusNew() {
    const result = await this.get("List", ["QSTTS"]);
    return result;
  }

  async getAdmissionStatus() {
    const result = await this.get("List", ["ASSTS"]);
    return result;
  }

  async getBaseOnOlinePay() {
    const result = await this.get("List", ["SGB"]);
    return result;
  }

  async getRegisterType() {
    const result = await this.get("List", ["REGTP"]);
    return result;
  }

  async getResultType() {
    const result = await this.get("List", ["RESLT"]);
    return result;
  }
  async getFeeCategory() {
    const result = await this.get("List", ["FCAT"]);
    return result;
  }
  async getGenderType() {
    const result = await this.get("List", ["GTYPE"]);
    return result;
  }
  async getLeaveType() {
    const result = await this.get("List", ["LVTYP"]);
    return result;
  }
  async getSubjectType() {
    const result = await this.get("List", ["SBTYP"]);
    return result;
  }
  async getMonth() {
    const result = await this.get("List", ["MONTH"]);
    return result;
  }

  async getPeriodtype() {
    const result = await this.get("List", ["TBRK"]);
    return result;
  }
  async getBankType() {
    const result = await this.get("List", ["BNKT"]);
    return result;
  }

  async getAccountStatus() {
    const result = await this.get("List", ["ACTS"]);
    return result;
  }

  async getQualificationType() {
    const result = await this.get("List", ["QTYPE"]);
    return result;
  }

  async getCourseType() {
    const result = await this.get("List", ["COTY"]);
    return result;
  }

  async getCampusType() {
    const result = await this.get("Campustype", ["COTY"]);
    return result;
  }

  async getSentBy() {
    const result = await this.get("List", ["SENTO"]);
    return result;
  }

  async getperiodStatus() {
    const result = await this.get("List", ["PRED"]);
    return result;
  }

  async getSectionType() {
    const result = await this.get("List", ["CPTY"]);
    return result;
  }

  async getAssignmentType() {
    const result = await this.get("List", ["ASNTT"]);
    return result;
  }

  async getMarksApplicable() {
    const result = await this.get("List", ["ISAPP"]);
    return result;
  }
  async getadjustmentType() {
    const result = await this.get("List", ["ADJT"]);
    return result;
  }
  async getSettlementAgainst() {
    const result = await this.get("List", ["CAD"]);
    return result;
  }
  async getassignmentStatus() {
    const result = await this.get("List", ["ASSTS"]);
    return result;
  }

  async getPaymentRunType() {
    const result = await this.get("List", ["PRT"]);
    return result;
  }
  async getPaymenttype() {
    const result = await this.get("List", ["MAAD"]);
    return result;
  }

  async getTaxMethod() {
    const result = await this.get("List", ["PM"]);
    return result;
  }
  async getEarningtype() {
    const result = await this.get("List", ["EMPBS"]);
    return result;
  }

  async getLoanLimit() {
    const result = await this.get("List", ["LL"]);
    return result;
  }

  async getLoanAvail() {
    const result = await this.get("List", ["LA"]);
    return result;
  }

  async getLoanInterest() {
    const result = await this.get("List", ["LI"]);
    return result;
  }
  async getLoanNature() {
    const result = await this.get("List", ["LN"]);
    return result;
  }
  async getReasonType() {
    const result = await this.get("List", ["LRSN"]);
    return result;
  }

  async getLeaveReasonType() {
    const result = await this.get("List", ["RSNTP"]);
    return result;
  }
  async getLeaveReasonType2() {
    const result = await this.get("List", ["RSNTP"]);
    return result;
  }

  async getDeductionType() {
    const result = await this.get("List", ["LDT"]);
    return result;
  }
  async getLoanCategory() {
    const result = await this.get("List", ["LCAT"]);
    return result;
  }

  async geAttnType() {
    const result = await this.get("List", ["Attyp"]);
    return result;
  }

  async getReNewOn() {
    const result = await this.get("List", ["RNW"]);
    return result;
  }

  async getLeavePolicy() {
    const result = await this.get("List", ["LPCY"]);
    return result;
  }

  async getLeaveAVGPolicy() {
    const result = await this.get("List", ["AVG"]);
    return result;
  }

  async getPeriodType() {
    const result = await this.get("List", ["PRTY"]);
    return result;
  }
  async getPeriodQuarter() {
    const result = await this.get("List", ["PRQR"]);
    return result;
  }

  async getPeriodsStatus() {
    const result = await this.get("List", ["PRST"]);
    return result;
  }
  async gettransactionType() {
    const result = await this.get("List", ["COLT"]);
    return result;
  }
  async gettransactionTypeInvoice() {
    const result = await this.get("List", ["TSIT"]);
    return result;
  }



  async getlinkActivity() {
    const result = await this.get("List", ["LACT"]);
    return result;
  }
  async gettype() {
    const result = await this.get("List", ["GRT"]);
    return result;
  }

  async getlovtye() {
    const result = await this.get("List", ["LACT"]);
    return result;
  }

  async getNodeType() {
    const result = await this.get("List", ["NDTY"]);
    return result;
  }
  async getSiteFor() {
    const result = await this.get("List", ["SFOR"]);
    return result;
  }

  async getSiteType() {
    const result = await this.get("List", ["STYP"]);
    return result;
  }

  async getInstituteType() {
    const result = await this.get("List", ["ORGT"]);
    return result;
  }

  async getInstituteStatus() {
    const result = await this.get("List", ["ORGS"]);
    return result;
  }

  async getSyllabusBasedOn() {
    const result = await this.get("List", ["SBO"]);
    return result;
  }

  async getSyllabusType() {
    const result = await this.get("List", ["STP"]);
    return result;
  }

  async getShared() {
    const result = await this.get("List", ["SSD"]);
    return result;
  }
  async getTextType() {
    const result = await this.get("List", ["TXF"]);
    return result;
  }
  async getpublishresult() {
    const result = await this.get("List", ["ONCS"]);
    return result;
  }

  async getSyllabusStatus() {
    const result = await this.get("List", ["ASSTS"]);
    return result;
  }

  async getAdmStatus() {
    const result = await this.get("List", ["APSTS"]);
    return result;
  }

  async getAccountLevel() {
    const result = await this.get("List", ["ACCL"]);
    return result;
  }

  async getAssignmentOrHomework() {
    const result = await this.get("List", ["ASGT"]);
    return result;
  }

  async getShareWith() {
    const result = await this.get("List", ["SHWT"]);
    return result;
  }

  async getActivityShared() {
    const result = await this.get("List", ["CSR"]);
    return result;
  }
  async getStorageType() {
    const result = await this.get("List", ["STGT"]);
    return result;
  }
  async getApprovalStatus() {
    const result = await this.get("List", ["PAPRV"]);
    return result;
  }

  async getTestStatus() {
    const result = await this.get("List", ["TSTS"]);
    return result;
  }
  async getStudentStatus() {
    const result = await this.get("List", ["STDS"]);
    return result;
  }

  async getRegistrationStatus() {
    const result = await this.get("List", ["REGST"]);
    return result;
  }

  async getDocumentCategory() {
    const result = await this.get("List", ["DCCT"]);
    return result;
  }

  async getfeeNature() {
    const result = await this.get("List", ["FNTR"]);
    return result;
  }

  async getReportPolicy() {
    const result = await this.get("List", ["RPTP"]);
    return result;
  }

  async getSubjectClass() {
    const result = await this.get("List", ["SBCLS"]);
    return result;
  }

  async getSubjectCategory() {
    const result = await this.get("List", ["SBCAT"]);
    return result;
  }

  async getManagmentType() {
    const result = await this.get("List", ["MNG"]);
    return result;
  }

  async getExamCategoryType() {
    const result = await this.get("List", ["EXTY"]);
    return result;
  }

  async getApplyOn() {
    const result = await this.get("List", ["APLON"]);
    return result;
  }

  async getShareType() {
    const result = await this.get("SharedWith", ["SHTW"]);
    return result;
  }

  async getEmpType() {
    const result = await this.get("EmployeeType", ["EMPTP"]);
    return result;
  }

  async getSharedWith() {
    const result = await this.get("SharedWith", ["SHWT"]);
    return result;
  }

  async getAssesmentType() {
    const result = await this.get("List", ["ASTYP"]);
    return result;
  }
  async getFineBase() {
    const result = await this.get("List", ["DATE"]);
    return result;
  }
  async getPostStatus() {
    const result = await this.get("List", ["FIPT"]);
    return result;
  }
  async getCertificateType() {
    const result = await this.get("List", ["CFTY"]);
    return result;
  }

  async getBaseOnType() {
    const result = await this.get("List", ["SCAT"]);
    return result;
  }
  async getDepreciationMethod() {
    const result = await this.get("List", ["FADM"]);
    return result;
  }
  async getDepreciationBaseOn() {
    const result = await this.get("List", ["FADB"]);
    return result;
  }
  async getPeriodFrequency() {
    const result = await this.get("List", ["FAPF"]);
    return result;
  }
  async getAssetType() {
    const result = await this.get("List", ["AT"]);
    return result;
  }
  async getDepreciationConvention() {
    const result = await this.get("List", ["DC"]);
    return result;
  }
  async getFIPostingTypeAgainstLedgerRegister(ledgerregisterid: any) {
    const result = await this.get("PostingTypes", ledgerregisterid);
    return result;
  }
  async getTransactiontypeInv() {
    const result = await this.get("transactiontype");
    return result;
  }
  async getItemNature() {
    const result = await this.get("List", ["ITMN"]);
    return result;
  }
  async getUnitType() {
    const result = await this.get("List", ["UNTT"]);
    return result;
  }

  async getGraceMark() {
    const result = await this.get("List", ["GMARK"]);
    return result;
  }

  async getDimensionType() {
    const result = await this.get("List", ["IMVT"]);
    return result;
  }
  async getCompulsory() {
    const result = await this.get("List", ["COMP"]);
    return result;
  }

  async getRefund() {

    const result = await this.get("List", ["RSRF"]);
    return result;
  }
  async getItemType() {
    const result = await this.get("List", ["IMIT"]);
    return result;
  }
  async getAcquisitionMethod() {
    const result = await this.get("List", ["FAAM"]);
    return result;
  }
  async getOwnership() {
    const result = await this.get("List", ["FAOS"]);
    return result;
  }
  async getActivityType() {
    const result = await this.get("List", ["FAAT"]);
    return result;
  }

  async getUOM() {
    const result = await this.get("List", ["UOM"]);
    return result;
  }

  async getRateB() {
    const result = await this.get("List", ["RB"]);
    return result;
  }
  async getRatebasis() {
    const result = await this.get("List", ["FXPS"]);
    return result;
  }

  async getRequester() {
    const result = await this.get("List", ["UCAT"]);
    return result;
  }
  async getdedectandcontribution() {
    const result = await this.get("List", ["DCOD"]);
    return result;
  }
  async getPayrollCategory() {
    const result = await this.get("List", ["PCTG"]);
    return result;
  }

  async getTaxable() {
    const result = await this.get("List", ["COMP"]);
    return result;
  }

  async getDriveType() {
    const result = await this.get("List", ["DRTYP"]);
    return result;
  }

  async getAccountType() {
    const result = await this.get("List", ["ACTYP"]);
    return result;
  }

  async getPublishedOn() {
    const result = await this.get("List", ["PUBLS"]);
    return result;
  }

  async getValidityOperator() {
    return await this.get("ValidityOperator");
  }
  async getValidity() {
    return await this.get("Validity");
  }
  async getCovergaeStatus() {
    const result = await this.get("List", ["TACT"]);
    return result;
  }

  async getFields(ControllerId: any) {
    ControllerId = ControllerId ? ControllerId : "null";
    return await this.get("Fields", [ControllerId]);
  }

  async getEmpCategory() {
    const result = await this.get("EmployeeCateg", ["ECAT"]);
    return result;
  }
  async getDocCategory() {
    const result = await this.get("List", ["DOCC"]);
    return result;
  }
  async getuserclientcat() {
    const result = await this.get("List", ["USCL"]);
    return result;
  }

  async getOrgtype() {
    const result = await this.get("List", ["OTYP"]);
    return result;
  }
  async getStudentType() {
    const result = await this.get("List", ["STT"]);
    return result;
  }

  async getRequestType() {
    const result = await this.get("List", ["PTRT"]);
    return result;
  }

  async getPriorityType() {
    const result = await this.get("List", ["PTYP"]);
    return result;
  }

  async getScopeType() {
    const result = await this.get("List", ["SCP"]);
    return result;
  }
  async getSortingBy() {
    const result = await this.get("List", ["SORT"]);
    return result;
  }

  async getEntityType() {
    const result = await this.get("List", ["OTYP"]);
    return result;
  }

  async getInsitutionType() {
    const result = await this.get("List", ["INSTY"]);
    return result;
  }

  async getFeetypeBaseOn() {
    const result = await this.get("List", ["FBSN"]);
    return result;
  }

  async getAdjustmentPolicy() {
    const result = await this.get("List", ["ADJP"]);
    return result;
  }

  async getPriority() {
    const result = await this.get("List", ["PRIT"]);
    return result;
  }

  async getComplaintCategory() {
    const result = await this.get("List", ["CMPT"]);
    return result;
  }

  async getInvoiceType() {
    const result = await this.get("List", ["INVT"]);
    return result;
  }

  async getResourceCategory() {
    const result = await this.get("List", ["PMRT"]);
    return result;
  }

  async getBindingType() {
    const result = await this.get("List", ["BIND"]);
    return result;
  }

  async getRateBasisType() {
    const result = await this.get("List", ["FXPS"]);
    return result;
  }

  async getPaymentType() {
    const result = await this.get("List", ["MAAD"]);
    return result;
  }

  async getOfficeReasonType() {
    const result = await this.get("List", ["FTYP"]);
    return result;
  }

  async getTranferRequestType() {
    const result = await this.get("List", ["TRTP"]);
    return result;
  }

  async GatePassStatus() {
    const result = await this.get("List", ["TRTP"]);
    return result;
  }


  async HandoverTo() {
    const result = await this.get("List", ["FOGS"]);
    return result;
  }
  async ReasonType() {
    const result = await this.get("List", ["FORT"]);
    return result;
  }

  async getAreaType() {
    const result = await this.get("List", ["STAR"]);
    return result;
  }
  async getAverageType() {
    const result = await this.get("List", ["MVTY"]);
    return result;
  }

  async getStorageDimType() {
    const result = await this.get("List", ["STTYP"]);
    return result;
  }

  async getTrackingDimType() {
    const result = await this.get("List", ["TRTYP"]);
    return result;
  }
  async getNatureType() {
    const result = await this.get("List", ["NTURE"]);
    return result;
  }
  async getItemLevel() {
    const result = await this.get("List", ["IMLV"]);
    return result;
  }
  async getRelationMaterial() {
    const result = await this.get("List", ["RMATE"]);
    return result;
  }
  async getLoadingOptions() {
    const result = await this.get("List", ["LOPT"]);
    return result;
  }
  async getUnitConversion() {
    const result = await this.get("List", ["UNCO"]);
    return result;
  }

  async getStatusForItem() {
    const result = await this.get("List", ["STTS"]);
    return result;
  }

  async getRefrenceType() {
    const result = await this.get("List", ["REFT"]);
    return result;
  }

  async getActivityBased() {
    const result = await this.get("List", ["ACTB"]);
    return result;
  }

  async getCommunicationType() {
    const result = await this.get("List", ["SRTY"]);
    return result;
  }

  async getNotificationType() {
    const result = await this.get("List", ["NTYPE"]);
    return result;
  }

  async getTransactiontype() {
    const result = await this.get("List", ["PSTT"]);
    return result;
  }

  async getRequestionPurpose() {
    const result = await this.get("List", ["RPURP"]);
    return result;
  }

  async getEPApplyOn() {
    const result = await this.get("List", ["ORAO"]);
    return result;
  }

  async getPositiontype() {
    const result = await this.get("List", ["PSPG"]);
    return result;
  }

  async getDocumentStatus() {
    const result = await this.get("List", ["STDC"]);
    return result;
  }

  async getExamType() {
    const result = await this.get("List", ["RSBON"]);
    return result;
  }

  async getFromType() {
    const result = await this.get("List", ["ATFRM"]);
    return result;
  }

  async getFileTypes() {
    const result = await this.get("List", ["FTYPE"]);
    return result;
  }
  async getDeviceTypes() {
    const result = await this.get("List", ["mtype"]);
    return result;
  }
  async getEPBaseOn() {
    const result = await this.get("List", ["ORBO"]);
    return result;
  }

  async getQuestionnaireTypeBasedOn() {
    const result = await this.get("List", ["QTBO"]);
    return result;
  }

  async getEPORPO() {
    const result = await this.get("List", ["ORPO"]);
    return result;
  }
  async getHomeworkType() {
    const result = await this.get("List", ["HMWR"]);
    return result;
  }

  async getShareCategory() {
    const result = await this.get("List", ["SHCA"]);
    return result;
  }
  async getInstallmentBase() {
    const result = await this.get("List", ["INSTB"]);
    return result;
  }

  async getRoomType() {
    const result = await this.get("List", ["RMTP"]);
    return result;
  }
  async getDiscountApplyOn() {
    const result = await this.get("List", ["CLON"]);
    return result;
  }

  async getBaseOnExam() {
    const result = await this.get("List", ["BSTE"]);
    return result;
  }
  async getaddifo() {
    const result = await this.get("List", ["GSTYP"]);
    return result;
  }

  async getmeetingbaseon() {
    const result = await this.get("List", ["SSD"]);
    return result;
  }
  async getmeetingtype() {
    const result = await this.get("List", ["MTNGT"]);
    return result;
  }
  async getguesttype() {
    const result = await this.get("List", ["GSTYP"]);
    return result;
  }
  async getsentto() {
    const result = await this.get("List", ["SCHSN"]);
    return result;
  }

  async getsection() {
    const result = await this.get("List", ["BSTE"]);
    return result;
  }
  async getcourse() {
    const result = await this.get("List", ["BSTE"]);
    return result;
  }
  async getsubject() {
    const result = await this.get("List", ["BSTE"]);
    return result;
  }
  async gettopic() {
    const result = await this.get("List", ["BSTE"]);
    return result;
  }
  async getstatus() {
    const result = await this.get("List", ["BSTE"]);
    return result;
  }
  async QuestionnaireScheduleType() {
    const result = await this.get("List", ["SCHTY"]);
    return result;
  }

  async gethosttype() {
    const result = await this.get("List", ["HSTTY"]);
    return result;
  }
  async meetinggethosttype() {
    const result = await this.get("List", ["METYP"]);
    return result;
  }

  async getMeetingType() {
    const result = await this.get("List", ["METYP"]);
    return result;
  }

  async getScholarshipType() {
    const result = await this.get("List", ["SDTYP"]);
    return result;
  }
  async getAnnouncementType() {
    const result = await this.get("List", ["ANTY"]);
    return result;
  }
  async getEventCategory() {
    const result = await this.get("List", ["EVCT"]);
    return result;
  }
  async getReportType() {
    const result = await this.get("List", ["RPTYP"]);
    return result;
  }

  async getFieldType() {
    const result = await this.get("List", ["RFTYP"]);
    return result;
  }

  async getStatusReport() {
    const result = await this.get("List", ["CSTS"]);
    return result;
  }

  async getTimeInterval() {
    const result = await this.get("List", ["TMIN"]);
    return result;
  }

  async getRule() {
    const result = await this.get("List", ["SMRL"]);
    return result;
  }

  async getDesination() {
    const result = await this.get("List", ["SMDG"]);
    return result;
  }

  async getStatusForcashreq() {
    const result = await this.get("List", ["CRST"]);
    return result;
  }

  async getScholarshipcategory() {
    const result = await this.get("List", ["DSC"]);
    return result;
  }

  async getScholarshipapplicablefor() {
    const result = await this.get("List", ["DSAF"]);
    return result;
  }

  async getAccountTypeForBankTransaction() {
    const result = await this.get("List", ["ACT"]);
    return result;
  }
  async getVoidType() {
    const result = await this.get("List", ["DSV"]);
    return result;
  }

  async getStudentAttendanceBasedOn() {
    const result = await this.get("List", ["SABOB"]);
    return result;
  }

  async getBusinessType() {
    const result = await this.get("List", ["BUSEN"]);
    return result;
  }
  async getemailType() {
    const result = await this.get("List", ["EMTY"]);
    return result;
  }


  //User Management LovService Start
  async getUsertype() {
    const result = await this.get("List", ["UTYPE"]);
    return result;
  }

  async getUserstatus() {
    const result = await this.get("List", ["USTAT"]);
    return result;
  }

  async getUserCategory() {
    const result = await this.get("List", ["UCAT"]);
    return result;
  }
  async getMenuCategory() {
    const result = await this.get("List", ["MCAT"]);
    return result;
  }
  // async getReportType() {
  //   const result = await this.get("List", ["RPTYP"]);
  //   return result;
  // }
  async utgetFieldType() {
    const result = await this.get("List", ["FTYPE"]);
    return result;
  }
  async utgetStatus() {
    const result = await this.get("List", ["CSTS"]);
    return result;
  }

  async getNumberType() {
    const result = await this.get("List", ["NTYPE"]);
    return result;
  }

  async getLineType() {
    const result = await this.get("List", ["LTYPE"]);
    return result;
  }

  async getBaseOnId() {
    const result = await this.get("List", ["BSON"]);
    return result;
  }
  async getconfigid() {
    const result = await this.get("DeviceActivity", ["MACT"]);
    return result;
  }
  async getletterforid() {
    const result = await this.get("List", ["LTHR"]);
    return result;
  }
  async getBaseonComplaint() {
    const result = await this.get("List", ["EMDES"]);
    return result;
  }
  async getTimeInPriority() {
    const result = await this.get("List", ["TIPR"]);
    return result;
  }
  async getTimeOutPriority() {
    const result = await this.get("List", ["TOPR"]);
    return result;
  }
  async getTimeUnit() {
    const result = await this.get("List", ["ATN"]);
    return result;
  }
  //User Management LovService End
  // for Employee Father Mother Guardian Student
  async getallEFMGS() {
    const result = await this.get("List", ["EFMGS"]);
    return result;
  }
  //////////////////////////////////////////
  async getallAgeBracket() {
    const result = await this.get("List", ["AGEB"]);
    return result;
  }
  ///////////////////////////////////////////
  async getcertifitempfield() {
    const result = await this.get("List", ["CTFD"]);
    return result;
  }
  async getdeducttype() {
    const result = await this.get("List", ["EXD"]);
    return result;
  }
  async getcertwithout() {
    const result = await this.get("List", ["CIV"]);
    return result;
  }
  async getstdviolationperiod() {
    const result = await this.get("List", ["SVIO"]);
    return result;
  }
  async getcalculation() {
    const result = await this.get("List", ["CPNA"]);
    return result;
  }
  async getService() {
    const result = await this.get("List", ["SHT"]);
    return result;
  }
  async getRoundingform() {
    const result = await this.get("List", ["NDWR"]);
    return result;
  }
  async getTaxCat() {
    const result = await this.get("List", ["TCAT"]);
    return result;
  }
  async getBaseonPercentage() {
    const result = await this.get("List", ["BOPA"]);
    return result;
  }
  async getChequeVoidType() {
    const result = await this.get("List", ["RETY"]);
    return result;
  }
  async getStatementType() {
    const result = await this.get("List", ["SBFV"]);
    return result;
  }
  async getReconOperator() {
    const result = await this.get("List", ["RMRO"]);
    return result;
  }
  async getFinancetemp() {
    const result = await this.get("List", ["FNT"]);
    return result;
  }
  async getFinancetempAccount() {
    const result = await this.get("List", ["FNTA"]);
    return result;
  }
  async getsize() {
    const result = await this.get("List", ["SIZE"]);
    return result;
  }
  async getVandorStatus() {
    const result = await this.get("List", ["VST"]);
    return result;
  }
  async getAccountClassification() {
    const result = await this.get("List", ["ACC"]);
    return result;
  }
  async getDimention() {
    const result = await this.get("List", ["DMOD"]);
    return result;
  }
  async getGratuitytype() {
    const result = await this.get("List", ["GRT"]);
    return result;
  }
  async getGratuityon() {
    const result = await this.get("List", ["GRO"]);
    return result;
  }
  async getYearbaseon() {
    const result = await this.get("List", ["YBO"]);
    return result;
  }
  async getbaseon() {
    const result = await this.get("List", ["BO"]);
    return result;
  }
  async GetCalculation() {
    const result = await this.get("List", ["CAL"]);
    return result;
  }
  async GetSummary() {
    const result = await this.get("List", ["SURY"]);
    return result;
  }
  async GetCoursestatus() {
    const result = await this.get("List", ["CSTEX"]);
    return result;
  }
  async preferredCommunication() {
    const result = await this.get("List", ["PRC"]);
    return result;
  }
  async Getrecommentstatus() {
    const result = await this.get("List", ["RENRE"]);
    return result;
  }
  async GetAdmissionAttendance() {
    const result = await this.get("List", ["ADATM"]);
    return result;
  }
  async GetRegTypeLov() {
    const result = await this.get("List", ["NRADM"]);
    return result;
  }
  async GetVsubtypeType() {
    const result = await this.get("VsubtypeType", ["PALST"]);
    return result;
  }
  async Getdefandsus() {
    const result = await this.get("List", ["SUDF"]);
    return result;
  }
  async GetDocSubTypeRef() {
    const result = await this.get("List", ["DCREF"]);
    return result;
  }
  async GetCalculateBy() {
    const result = await this.get("List", ["CALB"]);
    return result;
  }

  async GetCumulateBy() {
    const result = await this.get("List", ["CMLT"]);
    return result;
  }

  async GetPayImpact() {
    const result = await this.get("List", ["DCOD"]);
    return result;
  }
  async GetApplicableOn() {
    const result = await this.get("List", ["EMPI"]);
    return result;
  }
  async GetPayrollImpact() {
    const result = await this.get("List", ["ERTYP"]);
    return result;
  }
  async GetQuestionStatus() {
    const result = await this.get("List", ["QDF"]);
    return result;
  }
  async GetQuestionDiffi() {
    const result = await this.get("List", ["QDEF"]);
    return result;
  }
  async GetForPayroll() {
    const result = await this.get("List", ["SSD"]);
    return result;
  }
  async getValidateOnBehalf() {
    const result = await this.get("List", ["CWNM"]);
    return result;
  }

  async GetDatetoUpdate() {
    const result = await this.get("List", ["DTU"]);
    return result;
  }
  async GetPolicyType() {
    const result = await this.get("List", ["MSCT"]);
    return result;
  }
  async GetConductBy() {
    const result = await this.get("List", ["PRSF"]);
    return result;
  }

  async getmaritialStatusProfile() {
    const result = await this.get("List", ["MART"]);
    return result;
  }

  async GetStagesType() {
    const result = await this.get("List", ["STG"]);
    return result;
  }
  async GetPlanBaseOn() {
    const result = await this.get("List", ["PLBS"]);
    return result;
  }
  async GetReferenceType() {
    const result = await this.get("List", ["WRWOR"]);
    return result;
  }
  async getMemberShiptype() {
    const result = await this.get("List", ["MEMTP"]);
    return result;
  }
  async GetBulklocationtype() {
    const result = await this.get("List", ["RSB"]);
    return result;
  }
  async GetAcquisitionSource() {
    const result = await this.get("List", ["PRDO"]);
    return result;
  }
  async GetBookCondition() {
    const result = await this.get("List", ["NWOD"]);
    return result;
  }
  async getReportDownloadType() {
    const result = await this.get("List", ["DOWNT"]);
    return result;
  }
  async GetPostingType() {
    const result = await this.get("List", ["FIPT"]);
    return result;
  }
  async getAreaClass() {
    const result = await this.get("List", ["COREA"]);
    return result;
  }
  async getWarehouseManage() {
    const result = await this.get("List", ["WMTYP"]);
    return result;
  }

  async GetARTypes() {
    const result = await this.get("List", ["TRRS"]);
    return result;
  }

  async GetHifzDiaryActivity() {
    const result = await this.get("List", ["SSMNA"]);
    return result;
  }
  async GetQuranType() {
    const result = await this.get("List", ["QPQA"]);
    return result;
  }
  async GetQSOP() {
    const result = await this.get("List", ["QSOP"]);
    return result;
  }
  async GetPricingMetod() {
    const result = await this.get("List", ["PMTH"]);
    return result;
  }
  async GetPriceType() {
    const result = await this.get("List", ["PRTYP"]);
    return result;
  }
  async GetRoundingPolicy() {
    const result = await this.get("List", ["ROUP"]);
    return result;
  }

  async getQuotType() {
    const result = await this.get("List", ["QTTYP"]);
    return result;
  }
  async getPRStatusType() {
    const result = await this.get("List", ["APST"]);
    return result;
  }

  async GetTaxNatureType() {
    const result = await this.get("List", ["TNTYP"]);
    return result;
  }

  async getAttendStatus() {
    const result = await this.get("List", ["STAT"]);
    return result;
  }
  async getCognitiveLevels() {
    const result = await this.get("List", ["COGL"]);
    return result;
  }
  async getSalesTaxApply() {
    const result = await this.get("List", ["STAP"]);
    return result;
  }
  async Getuserbaseon() {
    const result = await this.get("List", ["STUBO"]);
    return result;
  }
  async GetEmailType() {
    const result = await this.get("List", ["SEP"]);
    return result;
  }
  async GetAdditionalEmailType() {
    const result = await this.get("List", ["EFSM"]);
    return result;
  }
  async getPurchaseType() {
    const result = await this.get("List", ["QTYP"]);
    return result;
  }
  async getPurchaseCriteria() {
    const result = await this.get("List", ["PCR"]);
    return result;
  }
  async getPurchaseOrderType() {
    const result = await this.get("List", ["POTY"]);
    return result;
  }

  async getVandorCategory() {
    const result = await this.get("List", ["CMGV"]);
    return result;
  }
  async GetRefundAgainst() {
    const result = await this.get("List", ["RFAG"]);
    return result;
  }
  async getInstallmentType() {
    const result = await this.get("List", ["EMCUS"]);
    return result;
  }
  // async getDocType() {
  //   const result = await this.get("List", ["DOCTY"]);
  //   return result;
  // }
  async getDocType2() {
    const result = await this.get("List", ["DTYPE"]);
    return result;
  }
  async getNature() {
    const result = await this.get("List", ["DTN"]);
    return result;
  }
  async getContractCategory() {
    const result = await this.get("List", ["EMPCC"]);
    return result;
  }

  async getEmployeeEducationStatus() {
    const result = await this.get("List", ["EMPED"]);
    return result;
  }
  async getSubjectLedBy() {
    const result = await this.get("List", ["SLB"]);
    return result;
  }
  async getDeductionOn() {
    const result = await this.get("List", ["SEDO"]);
    return result;
  }

  async getStudentAttPolType() {
    const result = await this.get("List", ["STPO"]);
    return result;
  }

  async getAdvanceAgainst() {
    const result = await this.get("List", ["DPDP"]);
    return result;
  }

  async getMealActivity() {
    const result = await this.get("List", ["MACTV"]);
    return result;
  }
  async getslalevel() {
    const result = await this.get("List", ["SLA"]);
    return result;
  }

  async GetShowWeeks() {
    const result = await this.get("List", ["SWEEK"]);
    return result;
  }

  async GetAssetType() {
    const result = await this.get("List", ["ATYPE"]);
    return result;
  }

  async getPLPPolicyBaseOn() {
    const result = await this.get("List", ["ASP"]);
    return result;
  }

  async getEventClass() {
    const result = await this.get("List", ["EVTCL"]);
    return result;
  }

  async getFirstPeriodConductBy() {
    const result = await this.get("List", ["BELTM"]);
    return result;
  }

  async getSubjectPriority() {
    const result = await this.get("List", ["PERDB"]);
    return result;
  }
}
