
import { Router } from "@angular/router";
import { Component, AfterViewInit, EventEmitter, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { LocalStorage } from "../../@core/helper/localStorage";
import { SMStudentinfoService } from '../../modules/Student/Profilling/studentprofile/studentprofile.service';
import { AppConstants } from '../../@constant/app.constant';
import { InstituteService } from "../../modules/Orgnization/setup/institute/institute.service";
import { GenEnum } from "src/app/@constant/general.enum";
import { NavComponent } from "src/app/@core/base/nav/Nav.Component";
// import { SystemPolicyService } from "src/app/modules/Administration/setup/system-policy.service";
import { ImageService } from "../services/image.service";
import { BaseComponent } from "src/app/@core/base/nav/Base.Component";
import { EmployeeinfohdrService } from "src/app/modules/employee-profile/transaction/employeeinfo.Service";
import { SystemPolicyService } from '../../modules/Administration/setup/system-policy.service';
import { DcsModalComponent } from '../../@core/components/dcs-modal/dcs-modal.component';
import * as moment from 'moment';
import { NavModalComponent } from '../../@core/base/nav/NavModal.Component';
import { JsonPipe } from "@angular/common";
import { StudentBirthdayComponent } from '../studentbirthday/student-birthday.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from "src/app/@core/service/user.service";
declare var $: any;

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
})
export class NavigationComponent extends NavComponent<any> implements AfterViewInit, OnInit {

  fb: FormBuilder;
  myForm!: FormGroup;
  @Output() toggleSidebar = new EventEmitter<void>();
  @ViewChild('Birthdaymodal') birthdaymodal?: DcsModalComponent;
  @ViewChild('Empbirthdaymodal') Empbirthdaymodal?: DcsModalComponent;
  @ViewChild('switchcampus') Switchcampus?: DcsModalComponent;
  @ViewChild("selectentity") selectentity?: ElementRef;
  @ViewChild("subselectentity") subselectentity?: ElementRef;
  @ViewChild("StdBirthday") StdBirthday?: StudentBirthdayComponent;
  @ViewChild("openentitymodal") openentitymodal?: DcsModalComponent;
  @ViewChild("opensubsidarymodal") opensubsidarymodal?: DcsModalComponent;
  @ViewChild("passwordmodal") passwordmodal?: DcsModalComponent;

  showBirth: boolean = false;
  entitylist: any
  showsubsidiaryCampus: any = 'false'
  subsidiaryEntitiesList: any = []
  subsidiaryentid: any
  entid: string = "";
  subEntityId: any;
  subEntityStxt: any;
  userImage: any;
  gridmenus: any = [];
  gridmenussubsdary: any = [];
  oldpass: boolean = false;
  newpass: boolean = false;
  confirmpass: boolean = false;
  showstdaurempmodal: boolean = false
  public config: PerfectScrollbarConfigInterface = {};
  currentTime: any;
  public showSearch = false;
  myDate = Date.now();
  active = 1;
  today: any = Date.now();
  fullname: string = '';
  studentdata: any;
  std_name: any = ''
  std_age: any = ''
  std_course: any = ''
  std_section: any = ''
  imagesourceentity: string;
  instituteList: any;
  instituteList1: any
  campFilter: any = { stxt: '' };
  campusName: any;
  userurl: any;
  imageSource: string = '../assets/images/background/user-info.jpg';
  studentbirthday: any;
  Stotal: any;
  EntityLogo: any;
  entitycount: any;
  selectedItem = Number(this.localStorage.get("selectedEntity"))
  public userData: any = this.localStorage.get("utuser");
  UserLog = JSON.parse(this.userData);
  constructor(private modalService: NgbModal,
    private studentinfo: SMStudentinfoService,
    private instituteService: InstituteService,
    private SystemPolicyservice: SystemPolicyService,
    private _GNImage: ImageService,
    private _EmployeeinfohdrService: EmployeeinfohdrService,
    private _loginservice: LoginService,
  ) {
    super(studentinfo);
    this.imagesourceentity = this.localStorage.get('OnlyUrl') + GenEnum.Image.imageUrl;
    setInterval(() => {
      const currentDate = new Date();
      this.currentTime = currentDate.toLocaleTimeString();
    }, 1000);
    this.fb = AppConstants.injector.get(FormBuilder);
  }

  async ngOnInit() {
    this.initilizeForm()
    this.campusName = this.localStorage.get("ClientStxt")
    this.showsubsidiaryCampus = (this.localStorage.get("enablesubsidiary"))
    this.EntityLogo = this.localStorage.get('EntityLogo');
    this.fullname = this.UserLog?.fullname;
    this.getAllStudent();
    this.BirthdayStudent()
    this.getEmployeeBirthDay();
    if (this.showsubsidiaryCampus == true || this.showsubsidiaryCampus == "true") {
      await this.getEntityAuto();
      await this.gridconfigmenu()
      await this.gridsubsidary()
    }
    else {
      await this.getEntity();
      await this.gridconfigmenu()
    }
    await this.getSubEntity()
    await this.getuserimage();
    this.subsidiaryEntitiesList = [];
    this.showBirth = false;
  }

  initilizeForm() {
    this.myForm = this.fb.group({
      'oldpass': ['', [Validators.required]],
      'newpass': ['', [Validators.required]],
      'confirmpass': ['', [Validators.required]],
    });
  }

  getstudbirthdaymodal() {
    if (this.studentbirthday && this.studentbirthday.length && this.studentbirthday.length > 0) {
      this.showBirth = true;
      this.birthdaymodal?.open('lg');
      this.showstdaurempmodal = false
      window.setTimeout(() => {
        $('body div').removeClass("modal-backdrop");
      })
    }
    else {
      this.showErrors("No Record Found !")
    }
  }

  getEmpdaymodal() {
    if (this.LookupData.empBirthdata && this.LookupData.empBirthdata.length && this.LookupData.empBirthdata.length > 0) {
      this.showBirth = true;
      this.Empbirthdaymodal?.open('lg');
      this.showstdaurempmodal = true
      window.setTimeout(() => {
        $('body div').removeClass("modal-backdrop");
      })
    }
    else {
      this.showErrors("No Record Found !")
    }
  }
  async Close(event: any) {
    setTimeout(async() => {
      await this.ngOnInit()
    }, 0);
}
  async Closeemp(event: any) {
    setTimeout(async() => {
      await this.ngOnInit()
    }, 0);
   
}

  async CloseBirthdayMod() {
    this.birthdaymodal?.closeModal()
    this.showBirth = false;
    this.showstdaurempmodal = false
    setTimeout(async() => {
      await this.ngOnInit()
    }, 0);
  }
  async CloseempbirthdayMod() {
    this.Empbirthdaymodal?.closeModal()
    this.showBirth = false;
    this.showstdaurempmodal = false
    setTimeout(async() => {
      await this.ngOnInit()
    }, 0);
  }

  // This is for Notifications
  notifications: any = [
    {
      btn: "btn-danger",
      icon: "ti-link",
      title: "Luanch Admin",
      subject: "Just see the my new admin!",
      time: "9:30 AM",
    },
    {
      btn: "btn-success",
      icon: "ti-calendar",
      title: "Event today",
      subject: "Just a reminder that you have event",
      time: "9:10 AM",
    },
    {
      btn: "btn-info",
      icon: "ti-settings",
      title: "Settings",
      subject: "You can customize this template as you want",
      time: "9:08 AM",
    },
    {
      btn: "btn-primary",
      icon: "ti-user",
      title: "Pavan kumar",
      subject: "Just see the my admin!",
      time: "9:00 AM",
    },
  ];

  // This is for Mymessages
  mymessages: any = [
    {
      useravatar: "assets/images/users/1.jpg",
      status: "online",
      from: "Pavan kumar",
      subject: "Just see the my admin!",
      time: "9:30 AM",
    },
    {
      useravatar: "assets/images/users/2.jpg",
      status: "busy",
      from: "Sonu Nigam",
      subject: "I have sung a song! See you at",
      time: "9:10 AM",
    },
    {
      useravatar: "assets/images/users/2.jpg",
      status: "away",
      from: "Arijit Sinh",
      subject: "I am a singer!",
      time: "9:08 AM",
    },
    {
      useravatar: "assets/images/users/4.jpg",
      status: "offline",
      from: "Pavan kumar",
      subject: "Just see the my admin!",
      time: "9:00 AM",
    },
  ];

  ngAfterViewInit() { }
  logout() {
    this.router.navigate(["/"]);
  }

  // async getAllStudent() {
  //   const dto = await this.studentinfo.getstudentbirthdays();
  //   if (dto && dto.Data) {
  //     dto.Data.forEach((element: any) => {
  //       var currentdate = new Date();
  //       var dbo = new Date(element.dateofbirth);
  //       var age = currentdate.getFullYear() - dbo.getFullYear();
  //       element.age = age;
  //     });
  //     this.studentdata = dto.Data;
  //     // console.log(this.studentdata);
  //   }
  //   if (this.studentdata && this.studentdata.length && this.studentdata.length > 0) {
  //     this.studentdata.forEach((element: any) => {
  //       this.std_name = element.firstname;
  //       this.std_age = element.age
  //       this.std_course = element.Course
  //       this.std_section = element.Section
  //     });
  //   }
  // }

  async getAllStudent() {
    const dto = await this.studentinfo.getstudentbirthdays();
    if (dto && dto.Data) {
      dto.Data.forEach((element: { dateofbirth: string | number | Date; age: number; }) => {
        var currentdate = new Date();
        var dbo = new Date(element.dateofbirth);
        var age = currentdate.getFullYear() - dbo.getFullYear();
        element.age = age;
      });
      this.LookupData.studentdata = dto.Data;
    }
  }

  async getEmployeeBirthDay() {
    this.LookupData.empBirthdata = []
    const dto = await this._EmployeeinfohdrService.getEmployeeBirthdays();
    if (dto && dto.Data) {
      dto.Data.forEach((element: any) => {
        var currentdate = new Date();
        var dbo = new Date(element.dateofbirth);
        var age = currentdate.getFullYear() - dbo.getFullYear();
        element.age = age;
        element.image = this.imageSource + element.filename;
      });
      this.LookupData.empBirthdata = dto.Data;
    }
  }

  async BirthdayStudent() {
    let dateFormat = AppConstants.settings.dateFormatforString;
    let dt = moment(new Date()).format(dateFormat);
    if (this.LookupData.studentdata) {
      let day = new Date(dt).getDate();
      let month = new Date(dt).getMonth();
      // this.studentbirthday = this.LookupData.studentdata.filter(o => new Date(o.DOB).getDate() === day && new Date(o.DOB).getMonth() === month);
      this.studentbirthday = this.LookupData.studentdata;
      this.Stotal = this.studentbirthday && this.studentbirthday.length && this.studentbirthday.length > 0 ? this.studentbirthday.length : 0;
      this.studentbirthday.forEach((element: { image: string; filename: string; }) => {
        if (element) {
          element.image = this.imageSource + element.filename;
        }
      });
    }
  }
  
  async getEntity() {
    var entitydata: any;
    var utuserents = JSON.parse(this.localStorage.get("utuserents"));
    if (utuserents && utuserents !== null) {
      var entities: any[] = [];
      utuserents.forEach((element: { entityid: any; }) => {
        entities.push(element.entityid);
      });
      if (entities && entities.length && entities.length > 0)
        entitydata = await this.instituteService.GetUserEntityData(entities)
    }
    if (entitydata && entitydata.IsSuccess) {
      this.entitylist = entitydata.Data;
      if (this.entitylist && this.entitylist.length > 0) {
        await this.getSubsidiaryEntites(this.entitylist[0].Id);
      }
    }
    if (entitydata && entitydata.Data && entitydata.Data.length && entitydata.Data.length > 0) {

      this.entitycount = entitydata.Data.length;
      entitydata.Data.forEach((element: { image: any; }) => {
        if (element) {
          element.image = this.imagesourceentity + element.image;
        }
      });
      this.instituteList = entitydata.Data;
    }
  }

  async getEntityAuto() {
    var entitydata: any;
    var utuserents = JSON.parse(this.localStorage.get("utuserents"));
    if (utuserents && utuserents !== null) {
      var entities: any[] = [];
      utuserents.forEach((element: { entityid: any; }) => {
        entities.push(element.entityid);
      });
      if (entities && entities.length && entities.length > 0)
        entitydata = await this.instituteService.GetUserEntityDataAuto(entities)
    }
    if (entitydata && entitydata.IsSuccess) {
      this.entitylist = entitydata.Data;
      if (this.entitylist && this.entitylist.length > 0) {
        await this.getSubsidiaryEntites(this.entitylist[0].Id);
      }
    }
    if (entitydata && entitydata.Data && entitydata.Data.length && entitydata.Data.length > 0) {
      this.entitycount = entitydata.Data.length;
      entitydata.Data.forEach((element: { image: any; }) => {
        if (element) {
          element.image = this.imagesourceentity + element.image;
        }
      });
      this.instituteList = entitydata.Data;
    }
  }

  async getSubEntity() {
    var entitydata: any;
    var utuserents = JSON.parse(this.localStorage.get("utuserents"));
    if (utuserents && utuserents !== null) {
      var entities: any[] = [];
      utuserents.forEach((element: { entityid: any; }) => {
        entities.push(element.entityid);
      });
      if (entities && entities.length && entities.length > 0)
        entitydata = await this.instituteService.GetUserSubEntityData(entities)
    }
    if (entitydata && entitydata.Data && entitydata.Data.length && entitydata.Data.length > 0) {
      this.entitycount = entitydata.Data.length;
      entitydata.Data.forEach((element: { image: any; }) => {
        if (element) {
          element.image = this.imagesourceentity + element.image;
        }
      });
      this.instituteList1 = entitydata.Data;
    }
  }

  async getPolicy(entityid: any) {
    const dto = await this.SystemPolicyservice.getPolicyV2(entityid);
    if (dto && dto.IsSuccess && dto.Data) {
      AppConstants.policy.dateFormat = dto.Data.dateformat;
      AppConstants.policy.timeFormat = dto.Data.timeformat;
      AppConstants.policy.gridpagging = dto.Data.gridpagging;
      AppConstants.policy.defaultctryid = dto.Data.defaultctryid;
      AppConstants.policy.defaultcityid = dto.Data.defaultcityid;
    } else {
      // this.loader.stopLoading();
      // this.errorForm.showErrors(dto.Errors);
    }
    return dto;
  }
 
  async setselectedEntity(selectedentityid: string | number) {
    let entityid = Number(this.localStorage.get("selectedEntity"))
    if (selectedentityid !== entityid) {
      // this.selectedItem = Number(selectedentityid)
      // if (this.showsubsidiaryCampus != 'false') {
      //   var data = this.instituteList1.filter((o: { Id: string | number; }) => o.Id === Number(selectedentityid));
      // }
      // else {
      //   var data = this.instituteList.filter((o: { Id: string | number; }) => o.Id === Number(selectedentityid));
      // }
      // if (data && data[0])
      //   this.localStorage.set("ClientStxt", data[0].stxt);
      if (this.localStorage.get("setselectedEntity"))
        this.localStorage.remove("setselectedEntity");
      if (this.localStorage.get("parentselectedEntity"))
        this.localStorage.remove("parentselectedEntity");
      this.localStorage.set("selectedEntity", selectedentityid);
      this.localStorage.set("parentselectedEntity", this.selectentity?.nativeElement.value);
      if (selectedentityid) {
        let rslt = await this.getPolicy(selectedentityid);
        if (rslt.IsSuccess) {
          var dto = await this.instituteService.getSelectedEntityInfoV2(selectedentityid);
          if (dto && dto.IsSuccess) {
            const legalEntity = dto.Data;
            if (legalEntity) {
              this.localStorage.set("ClientStxt", legalEntity.stxt);
              this.localStorage.set("ShortName", legalEntity.shortname);
              // this.loader.startLoading();
              // var dtoImage = await this._GNImage.get(legalEntity.imageid);
              // if (dtoImage && dtoImage.IsSuccess) {
              // this.loader.stopLoading();
              // const gnimage = dtoImage.Data;
              if (legalEntity && legalEntity.filename) {
                this.imageSource =
                  this.localStorage.get("OnlyUrl") +
                  GenEnum.Image.imageUrl +
                  legalEntity.filename;

                this.localStorage.set("EntityLogo", this.imageSource);
              }
              //  else console.log("logo image not found");
              // } else {
              // this.loader.stopLoading();
              // }
            } else
              console.log("Entity Data does not exist, entityid : " + selectedentityid);
            let utuser = JSON.parse(this.localStorage.get("utuser"));
            if (utuser) {
              if (utuser.usercatid == GenEnum.User_Category.Admin) {
              }
              if (utuser.usercatid == GenEnum.User_Category.Driver) {
              }
              if (utuser.usercatid == GenEnum.User_Category.Employee || utuser.usercatid == GenEnum.User_Category.Admin) {
                let referenceid = utuser.empid;
                if (referenceid) {
                  // this.loader.startLoading();
                  const loginemployee = await this._EmployeeinfohdrService.getLoginUserInfo(referenceid);
                  if (loginemployee && loginemployee.Data) {
                    // this.loader.stopLoading();
                    this.localStorage.set(
                      "LoginUserInfo",
                      JSON.stringify(loginemployee.Data)
                    );
                    if (this.userurl) {
                      this.localStorage.set(
                        "userpic",
                        this.userurl
                      );
                    }
                  } else {
                    // this.loader.stopLoading();
                    console.log("Employee does not exists, id = " + referenceid);
                  }
                } else {
                  // console.log("Employee not define on user");
                  this.showErrors("Employee not define on user");
                  return;
                }
              }
            }
            const result = await this.instituteService.getOrganizationStatusV2();
            if (result && result.IsSuccess && result.Data) {
              // this.router.navigate(["/Dashboard/Attendance"]);
              setTimeout(function () {
                window.location.reload();
              }, 2);
            } else {
              var errors: string[] = [];
              if (result && result.Errors) {
                result.Errors.forEach(element => {
                  errors.push(element.Description);
                });
                this.showErrors("Information Error");
                this.router.navigate(["/"]);
                return;
              }
            }
            // location.reload();
          } else {
            this.showErrors(
              dto.Errors.length > 0 ? dto.Errors : "Invalid user school"
            );
            this.navigate("login");
          }
          this.navigate("home");
        } else {
          this.showErrors(rslt.Errors);
        }
      } else {
        console.log("Entity id does not exists, id = " + selectedentityid);
        this.navigate("login");
      }
      this.selectedItem = Number(selectedentityid)
    }
  }

  navigate(arg0: string) {
    throw new Error("Method not implemented.");
  }

  switchcampusEvent() {
    this.Switchcampus?.open("");
    window.setTimeout(() => {
      $('body div').removeClass("modal-backdrop");
    })
  }

  async getEntity1() {
    var entitydata;
    var utuserents: any;
    var abc = this.localStorage.get("utuserents");
    utuserents = JSON.parse(abc);
    if (utuserents && utuserents !== null) {
      var entities: any = [];
      utuserents.forEach((element: any) => {
        entities.push(element.entityid);
      });
      if (entities && entities.length > 0) {
        entitydata = await this.instituteService.GetUserEntityData(entities);
      }
      if (entitydata && entitydata.IsSuccess) {
        this.entitylist = entitydata.Data;
        if (this.entitylist.length == 1) {
          this.entid = this.entitylist[0].Id;
          // await this.proceed();
        }
        if (this.entitylist && this.entitylist.length > 0) {
          await this.getSubsidiaryEntites(this.entitylist[0].Id);
        }
      } else {
      }
    }
  }

  async getSubsidiaryEntites(parentid: any) {
    if (this.showsubsidiaryCampus != 'false') {
      var dto = await this.instituteService.getSubsidiaryEntitiesAuto(parentid)
      if (dto != undefined && dto.IsSuccess && dto.Data != undefined) {
        this.subsidiaryEntitiesList = dto.Data
        if (this.subsidiaryEntitiesList && this.subsidiaryEntitiesList.length > 0) {
          this.subsidiaryentid = this.subsidiaryEntitiesList[0].Id;
        }
      }
      else {
      }
    }
  }

  async subEntityId12() {
    if (this.showsubsidiaryCampus != 'false') {
      if (this.selectentity && this.selectentity?.nativeElement) {
        var subselectentity = this.subselectentity?.nativeElement.value
      }
      await this.setselectedEntity(subselectentity)
    }
    else {
      if (this.selectentity && this.selectentity?.nativeElement) {
        var selectentity = this.selectentity?.nativeElement.value
      }
      await this.setselectedEntity(selectentity)
    }
  }

  async getuserimage() {
    this.userImage = null;
    let userId = JSON.parse(this.localStorage.get('utuser'));
    if (userId) {
      var image = await this._EmployeeinfohdrService.GetUserImage();
      this.userImage = this.localStorage.get('OnlyUrl') + GenEnum.Image.imageUrl + image.Data;
    }
  }

  async openentity() {
    await this.openentitymodal?.open("lg");
    window.setTimeout(() => {
      $('body div').removeClass("modal-backdrop");
    })
  }

  async opensubsidary() {
    await this.opensubsidarymodal?.open("lg");
    window.setTimeout(() => {
      $('body div').removeClass("modal-backdrop");
    })
  }

  async clickeddoouble(event: any) {
    this.entid = event.data.entityid
    await this.getSubsidiaryEntites(this.entid);
    this.openentitymodal?.closeModal();
  }

  async clickeddooublesubsidary(event: any) {
    this.subsidiaryentid = event.data.Id
    this.opensubsidarymodal?.closeModal();
  }

  async gridconfigmenu() {
    this.gridmenus = [
      {
        headerName: 'Id',
        field: 'id',
        width: 100,
        hide: true
      },
      {
        headerName: '',
        field: 'lookupcol',
        width: 15,
        filter: false,
        editable: false,
      },
      {
        headerName: 'Code',
        field: 'code',
        width: 80
      },
      {
        headerName: 'Entity',
        field: 'stxt',
        width: 250
      },
      {
        headerName: 'Address',
        field: 'address',
        width: 200,
        hide: true
      },
      {
        headerName: 'Region',
        field: 'regionstxt',
        width: 100
      },
      {
        headerName: 'City',
        field: 'cityname',
        width: 100
      },
      {
        headerName: 'State',
        field: 'state',
        width: 100
      },
      {
        headerName: 'Country',
        field: 'countrystxt',
        width: 100
      },
    ]
  }

  async gridsubsidary() {
    this.gridmenussubsdary = [
      {
        headerName: 'Id',
        field: 'id',
        width: 100,
        hide: true
      },
      {
        headerName: '',
        field: 'lookupcol',
        width: 15,
        filter: false,
        editable: false,
      },
      {
        headerName: 'Short Name',
        field: 'shortname',
        width: 80
      },
      {
        headerName: 'Campus',
        field: 'stxt',
        width: 250
      },
      {
        headerName: 'Address',
        field: 'address',
        width: 200,
        hide: true
      },
      {
        headerName: 'Region',
        field: 'regionstxt',
        width: 100
      },
      {
        headerName: 'City',
        field: 'cityname',
        width: 100
      },
      {
        headerName: 'State',
        field: 'state',
        width: 100
      },
      {
        headerName: 'Country',
        field: 'countrystxt',
        width: 100
      },
    ]
  }

  async openModal() {
    this.myForm.controls['oldpass'].setValue(undefined)
    this.myForm.controls['newpass'].setValue(undefined)
    this.myForm.controls['confirmpass'].setValue(undefined)
    this.myForm.controls['oldpass'].reset()
    this.myForm.controls['newpass'].reset()
    this.myForm.controls['confirmpass'].reset()
    this.passwordmodal?.open("md");
    window.setTimeout(() => {
      $('body div').removeClass("modal-backdrop");
    })
  }

  OldtextPassword() {
    this.oldpass = !this.oldpass;
  }

  NewtextPassword() {
    this.newpass = !this.newpass;
  }

  ConfirmtextPassword() {
    this.confirmpass = !this.confirmpass;
  }

  async confirmChange() {
    if (this.myForm.controls['oldpass'].value != undefined && this.myForm.controls['oldpass'].value != null && this.myForm.controls['oldpass'].value != "" &&
      this.myForm.controls['newpass'].value != undefined && this.myForm.controls['newpass'].value != null && this.myForm.controls['newpass'].value != "" &&
      this.myForm.controls['confirmpass'].value != undefined && this.myForm.controls['confirmpass'].value != null && this.myForm.controls['confirmpass'].value != "") {
      if (this.myForm.controls['newpass'].value === this.myForm.controls['confirmpass'].value) {
        var userdata = JSON.parse(this.localStorage.get("utuser"))
        const dto: any = {};
        dto.Data = {};
        dto.Data.password = this.myForm.controls["oldpass"].value;
        dto.Data.newpassword = this.myForm.controls["newpass"].value;
        dto.Data.confirmpassword = this.myForm.controls["confirmpass"].value;
        dto.Data.userid = userdata.userid
        dto.Data.username = userdata.username
        dto.Data.fullname = userdata.fullname
        const result = await this._loginservice.ChangePassword(dto)
        if (result && result.IsSuccess) {
          this.passwordmodal?.closeModal();
          this.showSuccess("Password Change successfully.")
        }
        else {
          this.showErrors(result.Errors)
        }
      }
      else {
        this.showErrors("The passwords you entered don't match")
      }
    }
    else {
      this.showErrors("All Fields are required !");
    }
  }
}

