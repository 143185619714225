import { DataTransferObject } from "../../../../@core/dto/DataTransferObject";
import { AppService } from "../../../../@core/service/App.Service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class InstituteService extends AppService<any> {
  constructor() {
    super("hdr_OrgLegalentity");
  }

  async getInstitution() {
    const result = await this.get("");

    return result;
  }

  async postEntitiesAgainstClients(clients: any) {
    const data = <DataTransferObject<any>>{
      Data: clients,
    };

    const result = await this.post("EntitiesAgainstClients", data);
    return result;
  }

  async getentityList(entityid: any) {
    const data = this.get("EntityList", [entityid]);
    return data;
  }
  async getentityListV2(entityid: any) {
    const data = this.get("EntityListV2", [entityid]);
    return data;
  }
  async getUserEntityListV2() {
    const data = this.get("UserEntityListV2");
    return data;
  }
  async getSelectedEntityInfoV2(entityid: any) {
    const data = this.get("SelectedEntityInfoV2", [entityid]);
    return data;
  }

  async getUserSchool() {
    const data = this.get("UserSchool");
    return data;
  }

  async getEntities() {
    const data = this.get("Entity");
    return data;
  }
  async getEntitiesss() {
    const data = this.get("Entityss");
    return data;
  }

  async getUserEntity() {
    const data = this.get("UserEntity");
    return data;
  }
  async getUserEntityNewAsync() {
    const data = this.get("UserEntityNew");
    return data;
  }


  async getUserEntityNew() {
    const data = this.get("GetEntityNew");
    return data;
  }
  async getSelectedEntities(
    countryid: any,
    regionid: any,
    stateid: any,
    cityid: any,
    topicid: any
  ) {
    const data = this.get("SelectedEntities", [
      countryid,
      regionid,
      stateid,
      cityid,
      topicid,
    ]);
    return data;
  }

  async getOrganizationStatus() {
    var entityid = Number(this.localStorage.get("selectedEntity"));
    const dto = await this.get("OrganizationStatus", [entityid]);
    return dto;
  }
  async getOrganizationStatusV2() {
    var entityid = Number(this.localStorage.get("selectedEntity"));
    const dto = await this.get("OrganizationStatusV2", [entityid]);
    return dto;
  }

  async getLoginUserEntities() {
    const dto = await this.get("EntityList");
    return dto;
  }
  async getLoginUserEntitiesBased(basedonid: any) {
    const dto = await this.get("EntityListNew", [basedonid]);
    return dto;
  }
  async getEntityListRegisterWise(acdyearid: any) {
    const dto = await this.get("EntityListRegisterWise", [acdyearid]);
    return dto;
  }
  async getEntityListRegisterNewWise(acdyearid: any, batchid: any) {
    const dto = await this.get("EntityListRegisterWise", [acdyearid, batchid]);
    return dto;
  }

  async GetClientEntities(clientid: Number) {
    const dto = await this.get("ClientEntities", [clientid]);
    return dto;
  }

  async getSelectedEntityInfo() {
    let entityid = this.localStorage.get("selectedEntity");
    const dto = await this.get("SelectedEntityInfo", [entityid]);
    return dto;
  }

  async GetUserEntityData(entities: Array<any>) {
    const data = <DataTransferObject<any>>{
      Data: entities,
    };

    const result = await this.post("UserEntityData", data);
    return result;
  }
  async GetUserEntityDataAuto(entities: Array<any>) {
    const data = <DataTransferObject<any>>{
      Data: entities,
    };

    const result = await this.post("UserEntityDataAuto", data);
    return result;
  }
  async GetUserEntityDatawithsub(entities: Array<any>) {
    const data = <DataTransferObject<any>>{
      Data: entities,
    };

    const result = await this.post("UserEntityDataWithSub", data);
    return result;
  }
  async GetUserSubEntityData(entities: Array<any>) {
    const data = <DataTransferObject<any>>{
      Data: entities,
    };

    const result = await this.post("UserSubEntityData", data);
    return result;
  }

  async getUserTypeWiseEntities(entitytypeid: Number) {
    const data = this.get("UserTypeWiseEntities", [entitytypeid]);
    return data;
  }

  async partialentitysaving(Data: any) {
    const _data = <DataTransferObject<any>>{
      Data: Data,
    };

    const result = await this.post("partialsaving", _data);
    return result;
  }

  async getholdingEntities() {
    const dto = await this.get("HoldingEntityList");
    return dto;
  }

  async getSubsidiaryEntities(parentid: any) {
    const dto = await this.get("SubsidiaryEntites", [parentid]);
    return dto;
  }

  async getSubsidiaryEntitiesAuto(parentid: any) {
    const dto = await this.get("SubsidiaryEntitesAuto", [parentid]);
    return dto;
  }
  async getSubsidiaryEntitiesAsync(parentid: any) {
    const dto = await this.get("SubsidiaryEntitesAsync", [parentid]);
    return dto;
  }

  async getClientEntityInfo() {
    let clientid = this.localStorage.get("clientid");
    const dto = await this.get("clientEntityInfo", [clientid]);
    return dto;
  }
  async GetCountSummaries(clientid: any, entityid: any) {

    const dto = await this.get('CampusDashboard', [clientid, entityid]);
    return dto;
  }

  async getEntityForClient(clientid: any) {
    const dto = await this.get("EntityForClient", [clientid]);
    return dto;
  }

  async getSubsidiaryEntity(parentid: any) {
    const dto = await this.get("SubsidiaryEntity", [parentid]);
    return dto;
  }
  async getCampusListClient(countryid: any, stateid: any, cityid: any, clientid: any) {
    const dto = await this.get("CampusList", [countryid, stateid, cityid, clientid]);
    return dto;
  }
  async getCampusListByCourseClient(countryid: any, stateid: any, cityid: any, clientid: any, courseid: any, baseon: any) {
    const dto = await this.get("CampusList", [countryid, stateid, cityid, clientid, courseid, baseon]);
    return dto;
  }

  async getEntitiesForCustomer(customerid : any){
    const result = await this.get('EntitiesForCustomer',[customerid]);
    return result;
  }

  // 05-Jan-2024
  // Amir Moavia
  // Get All Entity With Subsidiary
  // Start
  async GetAllEntityWithSubsidiary(entities: Array<any>) {
    const data = <DataTransferObject<any>>{
      Data: entities,
    };

    const result = await this.post("AllEntityWithSubsidiary", data);
    return result;
  }
  // End
}
