import { LookupConfiguration } from "src/app/@constant/config";
import { MapConfiguration } from '../../../../../@constant/config';



export const issueConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'issuanceno',
            caption: 'Issuance No',
            width: '120px'
        },
        {
            name: 'issuancedate',
            caption: 'Issuance Date',
            width: '120px',
            type: 'date'
        },
        {
            name: 'ltxt',
            caption: 'Remarks',
            width: '120px'
        },
        {
            name: 'EREmployeeinfohdr1.firstname',
            caption: 'Issue By',
            width: '120px'
        }        
    ],
};


export const employeeConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'employeecode',
            caption: 'Code',
            width: '110px'
        },
        {
            name: 'firstname',
            caption: 'First Name',
            width: '180px'
        },
        {
            name: 'lastname',
            caption: 'Last Name',
            width: '180px'
        },
        {
            name: 'departmentstxt',
            caption: 'Department',
            width: '180px'
        },
        {
            name: 'designationstxt',
            caption: 'Designation',
            width: '180px'
        }
    ]
};

export const IssueMediaQueryConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'bookno',
            caption: 'Media ID',
            width: '100px'
        },
        {
          name: 'barcode',
          caption: 'Bar Code',
          width: '100px'
      },
        {
            name: 'title',
            caption: 'Title',
            width: '150px'
        },
        {
            name: 'tag',
            caption: 'Tag',
            width: '120px'
        },
        {
            name: 'mediatype',
            caption: 'Media Type',
            width: '100px'
        },
        {
            name: 'mediacategory',
            caption: 'Media Category',
            width: '150px'
        },
        {
            name: 'publisher',
            caption: 'Publisher',
            width: '100px'
        },
        {
          name: 'author',
          caption: 'Author',
          width: '150px'
      },
    ],
  }

  export const mapConfigIssuemedia = {
    issueMedia: <MapConfiguration[]>[
        {
            field: "author",
            mapField: "author"

        },
        {
            field: "publisher",
            mapField: "publisher"

        },

    ]


};
export const BookConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'title',
            caption: 'Media Name',
            width: '100px'
        },
        {
            name: 'author',
            caption: 'Author',
            width: '100px'
        },
        {
            name: 'publisher',
            caption: 'Publisher',
            width: '100px'
        },
        {
            name: 'edition',
            caption: 'Edition',
            width: '100px'
        },
        {
            name: 'year',
            caption: 'Year',
            width: '100px'
        },

    ],
};
export const RegularMemberConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'membername',
            caption: 'Name',
            width: '100px'
        },
        {
            name: 'membershiptype',
            caption: 'Membership Type',
            width: '150px'
        },
        {
            name: 'status',
            caption: 'Status',
            width: '100px'
        },
        {
            name: 'Gender',
            caption: 'Gender',
            width: '100px'
        },
        {
            name: 'contactno',
            caption: 'Contact No',
            width: '100px'
        },

    ],
};
  