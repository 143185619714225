import { customButtonList, Enum } from "./../../../@constant/config";
import { ModalComponent, ModalManager } from "ngb-modal";
import {
  Component,
  ViewChild,
  ContentChild,
  Output,
  EventEmitter,
  AfterViewInit,
  Input,
  OnInit,
  HostListener,
  ElementRef,
} from "@angular/core";
import { List } from "linqts";
import { NavModalComponent } from "../../base/nav/NavModal.Component";
import { Subscription } from "rxjs";
// import { DcsDocfieldModalComponent } from '../dcs-docfield-modal/dcs-docfield-modal.component';
import { GenEnum } from "src/app/@constant/general.enum";
import { userInfo } from "os";
import { DcsValidatorModalComponent } from '../dcs-validator-modal/dcs-validator-modal.component';
import { ErrorComponent } from "../error/error.component";
import { DcsDocfieldModalComponent } from "../dcs-docfield-modal/dcs-docfield-modal.component";
// import { LocalStorage } from "../../helper/localStorage";
import { DcsPrintviewerComponent } from "../dcs-printviewer/dcs-printviewer.component";

declare var $: any;

@Component({
  selector: "dcs-modal",
  templateUrl: "dcs-modal.component.html",
})
export class DcsModalComponent implements AfterViewInit, OnInit {
  Size: any;
  ModalSize: any;
  ngAfterViewInit(): void {

    this.loadData();
  }
  private lookupSubscription?: Subscription;

  async ngOnInit() {
    // field configration work don't remove
    // var userInfo: any = userInfo = JSON.parse(localStorage.getItem("utuser") || '{}');
    // if (userInfo && userInfo.usertypeid && userInfo.usertypeid == GenEnum.UserType.SuperAdmin) {
    //   this.showConfigButton = true;
    // }
    // field configration work don't remove

    var userclients: any = JSON.parse(localStorage.getItem("userclients") || '{}');
    if (userclients && userclients.length && userclients.length > 0 && userclients[0].utclient && userclients[0].utclient.enableai == true) {
      var menudtl: any = JSON.parse(localStorage.getItem("menues") || '{}');
      var menuid: any = JSON.parse(localStorage.getItem("menuid") || '');
      var matchmenu = menudtl.filter((a: any) => a.id == menuid)
      if (matchmenu && matchmenu.length && matchmenu[0].moduleaienable) {
        this.loginuserAI = true;
      }
      else {
        this.loginuserAI = false;
      }
    } else {
      this.loginuserAI = false;
    }

  }

  async drag() {
    $(".modal-dialog").draggable({
      handle: ".modal-header",
    });
    $("modal-dialog").css({ top: 0, left: 0, right: 0, bottom: 0 });
  }

  @ContentChild("ngContent") screen: NavModalComponent<any> | any;
  // @ViewChild("FieldConfigModal") FieldConfigModal?: DcsDocfieldModalComponent;
  @ViewChild("ValidFieldModal") ValidFieldModal?: DcsValidatorModalComponent;
  @ViewChild('dcserror') dcserror: ErrorComponent | any;
  @ViewChild("printViewer") printViewer?: DcsPrintviewerComponent;

  // showConfigButton: boolean = false;
  loginuserAI: boolean = false;
  @Input()
  showList: boolean = false;
  @Input()
  backdrop: boolean = true;

  @Input()
  closeOnOutsideClick: boolean = false;

  @Output()
  toolbarClickList: EventEmitter<customButtonList> = new EventEmitter<customButtonList>();

  groups: any = [];
  FormData: any;
  FieldConfig: boolean = false;

  data: customButtonList[] = [];

  @Input()
  set customButtonList(value: any) {
    this.data = value;
    this.loadData();
  }
  get customButtonList() {
    return this.data;
  }

  // field configration work don't remove
  // openDocfieldModal() {

  //   if (this.FormData != undefined) {
  //     this.FieldConfig = true;
  //     this.FieldConfigModal?.open();
  //   }
  //   else {
  //     this.dcserror.showErrors("There Is No Data Fields For Configuration", 'Alert');
  //   }
  // }
  // closeDocfieldModal() {
  //   this.FieldConfig = false;
  //   this.FieldConfigModal?.closeModal();
  // }
  // field configration work don't remove
  CLoseValidFieldModal() {
    this.ValidFieldModal?.closeModal();
  }
  OpenValidFieldModal() {
    this.ValidFieldModal?.open("md");
  }
  customButtonListClick(item: customButtonList) {
    this.toolbarClickList.emit(item);
  }

  @Input()
  public Caption: String = "";

  @Input()
  screenCode: string = "";

  width: string = Enum.modalSize.Large;
  height: string = "auto";

  @Input()
  modaltype: number = Enum.modalType.Normal;

  @Input()
  displayLastRecord: boolean = false;

  @Input()
  showClose: boolean = true;

  @Input()
  mode: number = Enum.Mode.Default;

  @Input()
  set lvl(val: number) {
    this.zindex += Number(val);
  }
  get lvl() {
    return this.zindex;
  }

  @Input()
  modalSize: string = Enum.modalSize.Large;

  @Input()
  customClose: boolean = false;

  zindex: number = 1049;

  @ViewChild("myModal") myModal?: ModalComponent;
  private modalRef: any;

  @Input()
  hideNew: boolean = false;

  @Input()
  hideSave: boolean = false;

  @Input()
  hideSaveClose: boolean = false;

  @Input()
  hideaibtn: boolean = false;

  @Input()
  hideClose: boolean = false;

  @Input()
  hideprint: boolean = false;

  @Input()
  hideModalToolbar: boolean = false;

  constructor(private modalService: ModalManager,) { }

  async open(size: string = "lg", isCentered: boolean = false) {
    await this.drag();

    if (this.screen) {
      this.Caption =
        this.Caption && this.Caption === this.screen.title
          ? this.Caption
          : this.screen.title
            ? this.screen.title
            : "-";
    }

    if (!this.modalRef || !this.modalRef.isOpened) {
      this.modalRef = this.modalService.open(this.myModal, {
        size: size,
        modalClass: "mymodal",
        hideCloseButton: this.hideClose,
        centered: isCentered,
        // backdrop: this.backdrop,
        animation: true,
        keyboard: false,
        closeOnOutsideClick: this.closeOnOutsideClick,
        backdropClass: "modal-backdrop",
      });

      if (this.screen) {
        this.screen.LookupData = [];
        await this.screen.initialize();

        if (this.screen.myForm) {
          this.FormData = this.screen.myForm.value;
        }


      }
      if (this.mode === Enum.Mode.New) {
        // this.hideprint = true
        localStorage.setItem("AccessKey", "create")
      }
      else if (this.mode === Enum.Mode.Edit) {
        // this.hideprint = false
        localStorage.setItem("AccessKey", "update")
      }
      else if (this.mode === Enum.Mode.Display) {
        // this.hideprint = false
        localStorage.setItem("AccessKey", "display")
      }
      this.triggeronOpen(this.screen);
      if (this.displayLastRecord && this.mode !== Enum.Mode.New) {
        await this.screen?.moveLast();
      }
    }
  }

  async customClosed() {
    this.myModal?.close();
  }

  async closeModal() {
    await this.innerClose();

    if (this.modalService) {
      // safe to use the function
      this.modalService.close(this.modalRef);
    }

  }
  // async openFieldConfig() {
  //   this.FieldConfigModal?.open();
  // }
  localStorage: any
  async printrecord() {
    var data = JSON.parse(localStorage && localStorage.utdocsubtyperptdtl ? localStorage.utdocsubtyperptdtl : []);
    if (data && data.length && data.length > 0) {
      await this.openprintmodal(data)
    }
    // else{
    //   this.dcserror?.showErrors("There is no report Configuration in this screen.");
    // }
  }
  async openprintmodal(data: any) {
    var params: any = []
    var reportcode = ""
    var reporttitle = ""
    data.forEach((ele: any) => {
      reportcode = ele.reportname
      reporttitle = ele.reportstxt
      params.push(
        { key: ele.reportfieldstxt, value: this.screen.myForm.controls[ele.modelfieldid].value },
      );
    });
    if (params) {
      this.printViewer?.openReport(
        reportcode,
        reporttitle,
        true,
        false,
        params,
        true
      );
    }
  }
  private async loadData() {

    if (this.data) {
      this.groups = new List<customButtonList>(this.data)
        .Select((o) =>
          o.grpname === "" || o.grpname === null || o.grpname === undefined
            ? "Options"
            : o.grpname
        )
        .Distinct()
        .ToArray();
    }
  }

  filterData(grp: any): Array<customButtonList> {
    if (grp === "Options") {
      grp = undefined;

      return this.data.filter((o) => o.grpname === grp);
    } else {
      return this.data.filter((o) => o.grpname === grp);
    }
  }

  disabledAction = false;
  async saveAndClose() {
    if (this.screen && !this.disabledAction) {
      this.disabledAction = true;
      const issuccess = await this.screen.save();
      this.disabledAction = false;
      if (issuccess) {
        await this.closeModal();
        // await this.innerClose();
      }
      else {
        this.ValidFieldModal?.getdata(this.screen.myForm.controls);
        if (this.ValidFieldModal?.fetchFields && this.ValidFieldModal?.fetchFields.length != 0) {
          this.OpenValidFieldModal();
        }

      }
    }
  }
  async Saveonly() {
    const issuccess = await this.screen?.save();
    if (issuccess) {
    }
    else {
      this.ValidFieldModal?.getdata(this.screen?.myForm.controls);
      if (this.ValidFieldModal?.fetchFields && this.ValidFieldModal?.fetchFields.length != 0) {
        this.OpenValidFieldModal();
      }
    }
  }

  // Modal side menu working

  isShown: boolean = false;

  dropdownToggle($event: { stopPropagation: () => void }) {
    $event.stopPropagation();
    this.isShown = !this.isShown;
  }
  @ViewChild("modalsidemenu") modalsidemenu?: ElementRef;
  @HostListener("document:click", ["$event"]) DocumentClick(event: Event) {
    if (
      this.modalsidemenu &&
      this.modalsidemenu.nativeElement &&
      this.modalsidemenu.nativeElement.contains(event.currentTarget)
    ) {
      this.isShown = true;
    } else {
      this.isShown = false;
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.modalService.close(this.modalRef);
  }
  outsideClick() {
    this.isShown = false;
  }

  // End

  @Output()
  onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onClose: EventEmitter<any> = new EventEmitter<any>();

  public triggeronOpen(event: any) {
    this.onOpen.emit(event);
  }

  public triggeronClose(event: any) {
    this.onClose.emit(event);
  }

  async innerClose(triggerclose: boolean = true) {
    if (this.lookupSubscription) {
      this.lookupSubscription.unsubscribe();
    }
    if (this.screen && this.screen.service) {
      this.screen.service.clearCondition();
    }
    if (this.screen) {
      this.screen.clear();
    }

    if (triggerclose) {
      this.triggeronClose(this);
    } else {
      if (this.customClose) {
        this.triggeronClose(this);
      }
    }
  }

  async aiclick() {
    this.screen.generateText()
  }
}
