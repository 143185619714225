import { LookupConfiguration } from "../../../../@constant/config";


export const OrgDepartmentConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'depcode',
            caption: 'Code',
            width: '100px'
        },
        {
            name: 'depstxt',
            caption: 'Department',
            width: '200px'
        },
        //not required
        // {
        //     name: 'depltxt',
        //     caption: 'Description',
        //     width: '250px'
        // }
    ]
};

export const DepartmentConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'depcode',
            caption: 'Code',
            width: '100px'
        },
        {
            name: 'stxt',
            caption: 'Department',
            width: '200px'
        },
    ]
};

export const OrgDepartmentListerConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'code',
            caption: 'Code',
            width: '100px'
        },
        {
            name: 'stxt',
            caption: 'Department',
            width: '200px'
        },
        // {
        //     name: 'ltxt',
        //     caption: 'Description',
        //     width: '250px'
        // }
    ]
};