import { LookupConfiguration } from "../../../../@constant/config";

export const FICOAConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'code',
            caption: 'Code',
            width: '110px'
        },
        {
            name: 'stxt',
            caption: 'Account Name',
            width: '200px'
        },
        // {
        //     name: 'mst_FIAcctnature.stxt',
        //     caption: 'Account Nature',
        //     width: '150px'
        // },
        
        {
            name: 'trn_FIChart.stxt',
            caption: 'Account Group',
            width: '180px'
        }
    ],
};

export const COAConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'code',
            caption: 'Code',
            width: '110px'
        },
        {
            name: 'stxt',
            caption: 'Account',
            width: '300px'
        },
        // {
        //     name: 'mst_FIAcctnature.stxt',
        //     caption: 'Account Nature',
        //     width: '150px'
        // },
        
        {
            name: 'trn_FIChart.stxt',
            caption: 'Account Group',
            width: '180px'
        }
    ],
};


export const hdr_FICOAConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'code',
            caption: 'Code',
            width: '110px'
        },
        {
            name: 'stxt',
            caption: 'Account',
            width: '300px'
        },
        {
            name: 'groupstxt',
            caption: 'Account Group',
            width: '180px'
        }
    ],
};

export const FIAccttypeConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'stxt',
            caption: 'Account Type',
            width: '250px'
        },
    ],
};


export const FITrantypeConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'stxt',
            caption: 'Code',
            width: '110px'
        },
        {
            name: 'ltxt',
            caption: 'Name',
            width: '200px'
        },
    ],
};

export const DRCRProposalConfig = {
    lister: <LookupConfiguration[]>[
        {
            name: 'stxt',
            caption: 'Code',
            width: '110px'
        },
        {
            name: 'ltxt',
            caption: 'Dr/Cr Proposal',
            width: '200px'
        },
    ],
};
export const DRCRRequirementConfig = {
    lister: <LookupConfiguration[]>[
        {
            name: 'stxt',
            caption: 'Code',
            width: '110px'
        },
        {
            name: 'ltxt',
            caption: 'Dr/Cr Requirement',
            width: '200px'
        },
    ],
};

export const BalanceControlConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'stxt',
            caption: 'Code',
            width: '110px'
        },
        {
            name: 'ltxt',
            caption: 'Balance Control',
            width: '250px'
        },
    ],
};


export const FIPostkeyConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'code',
            caption: 'Code',
            width: '110px'
        },
        {
            name: 'stxt',
            caption: 'Post Key',
            width: '250px'
        },
    ],
};


export const FIAcctvalidateConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'stxt',
            caption: 'Validate Posting',
            width: '250px'
        },
    ],
};
export const FICurrencyValidateConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'stxt',
            caption: 'Currency Validate',
            width: '250px'
        },
    ],
};

export const FIChartdtlConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'code',
            caption: 'Code',
            width: '110px'
        },
        {
            name: 'stxt',
            caption: 'Account Group',
            width: '200px'
        },

        {
            name: 'mst_FIAcctnature.stxt',
            caption: 'Account Nature',
            width: '150px'
        }
    ],
};

export const FIChartConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'code',
            caption: 'Code',
            width: '110px'
        },
        {
            name: 'stxt',
            caption: 'Account Chart',
            width: '200px'
        },
    ],
};

export const FIClassification = {
    lister: <LookupConfiguration[]>[
        {
            name: 'code',
            caption: 'Code',
            width: '110px'
        },
        {
            name: 'stxt',
            caption: 'Name',
            width: '150px'
        },

        {
            name: 'vtype',
            caption: 'Type',
            width: '150px'
        }
    ],
};
export const Classification = {
    lister: <LookupConfiguration[]>[
        {
            name: 'code',
            caption: 'Code',
            width: '110px'
        },
        {
            name: 'stxt',
            caption: 'Classification',
            width: '150px'
        },

        {
            name: 'vtype',
            caption: 'Type',
            width: '150px'
        }
    ],
};
