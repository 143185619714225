import { Injectable } from "@angular/core";
import { AppService } from "../../../../../@core/service/App.Service";


@Injectable()
export class SMAcadpolicyService extends AppService<any> {

    constructor() {
        super('SMAcadpolicy');
    }

    async getEntityPolicy(entityid:any) {
        const result = await this.get('EntityPolicy', [entityid]);
        return result;
    }


    async getAcademicPolicy(entityid:any) {
        const result = await this.get('EntityPolicy', [entityid]);
        return result;
    }
    async getExamPolicy() {
        var clientid = this.localStorage.get('clientid');
        const result = await this.get('ExamsPolicy', [clientid]);
        return result;
    }
}