import { Component, ElementRef, HostListener, Input, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer } from "@angular/platform-browser";
import { List } from "linqts";
import { AppConstants } from "../../../@constant/app.constant";
import { BaseComponent } from "../../base/nav/Base.Component";
import { SyncValue } from "../../service/syncdata.service";
import * as moment from "moment";
import { DCSReportService } from "./dcs-report.service";
import { DCSReportConfigDtlService } from './dcs-reportconfigdtl.service'
import { DCSCustomService } from "./dcs-custom.service";
import { DcsDocfieldModalComponent } from '../dcs-docfield-modal/dcs-docfield-modal.component';
import { GenEnum } from 'src/app/@constant/general.enum';
import { DatePipe } from '@angular/common';
import { DCSDimensionbaseService } from "../dcs-dimention/dcs-dimentionbase/dcs-dimentionbase.service";
import { HttpParams } from "@angular/common/http";
import { OrgDepartmentConfigs } from "src/app/modules/Orgnization/setup/orgdepartment/orgdepartment.config";
import { employeeConfigs } from "src/app/modules/Library/General/Transaction/Issue/issue.Config";
import { DcsModalComponent } from '../dcs-modal/dcs-modal.component';
import { BaseService } from "../../service/Base.Service";
import { customButton, CustomButtonType, UserClientConfigs } from "src/app/@constant/config";
import { DCSAggridComponent } from "../dcs-aggrid/dcs-aggrid.component";
import { LovService } from "src/app/shared/services/lov.service";

@Component({
  selector: "dcs-report",
  templateUrl: "dcs-report.component.html",
  styleUrls: ["dcs-report.component.scss"],
})
export class DcsReportComponent extends BaseComponent {
  protected fb: FormBuilder = new FormBuilder();
  @ViewChild('dimensionModal') dimensionModal?: DcsModalComponent | any;
  @ViewChild('agGrid') agGrid?: DCSAggridComponent | any;
  @ViewChild('configgrid') configgrid?: DCSAggridComponent | any;
  @ViewChild("newtabs1") public tabs1: ElementRef | any;
  public myForm?: FormGroup | any;
  _showCriteria: boolean = true;
  _isCollapsed: boolean = false;
  data: any = {};
  allconfig: reportDetail[] = [];
  config: reportDetail[] = [];
  configDimensiondata: reportDetail[] = [];
  directconfig: any = [];
  directconfigDimensiondata: any = [];
  configDimension: Dimensions[] = [];
  depandentOn: any = [];
  collapsed: boolean = false;
  _isAutoGenerated: boolean = false;
  _height: any = "1200px";
  dimenstiontextarea: any;
  columnListDefslegact: any;
  modalgriddata: any = [];
  modaldata: any = [];
  customButtonEntity: any;
  selectedlovstatusid: any;
  mainurls: any;
  hidereportTab: boolean = false;
  showloader: boolean = false;
  showReportPanel: boolean = false;
  connectConfig: any;

  @HostListener('window:message', ['$event'])
  onMessage(event: any) {
    this.receiveCrossFunc(event);
  }

  public defaultValues: Array<SyncValue> = Array<SyncValue>();
  public columnDefs: any = [];
  private listerConfigs: Array<listerConfig> = [
    {
      key: "dep",
      value: OrgDepartmentConfigs.lister,
    },
    {
      key: "empid",
      value: employeeConfigs.lister,
    },
  ];
  @Input()
  get isAutoGenerated(): boolean {
    return this._isAutoGenerated;
  }

  set isAutoGenerated(value) {
    this._isAutoGenerated = value;
  }

  @Input()
  get showCriteria(): boolean {
    return this._showCriteria;
  }

  set showCriteria(value) {
    this._showCriteria = value;
  }

  @Input()
  get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  set isCollapsed(value) {
    this._isCollapsed = value;
  }

  @Input()
  get height(): boolean {
    return this._height;
  }

  set height(value) {
    this._height = value;
  }

  @Input()
  hidegenerate: boolean = false

  @ViewChild("frame") public iframe!: HTMLIFrameElement;
  @ViewChild("FieldConfigModal") FieldConfigModal?: DcsDocfieldModalComponent;
  @ViewChild('openmodal') openmodal: DcsModalComponent | any;

  set reportName(value: string) { }
  url: any;
  FieldConfig: boolean = false;
  showConfigButton: boolean = false;
  dateFormat: any;
  activeuser: any;
  activereport: any;
  reportname: any;
  activeclientid: any;
  callhit: any = [];
  callrender: boolean = false;
  menutype: number = 0;

  constructor(
    private reportService: DCSReportService,
    private customService: DCSCustomService,
    private _lovService: LovService,
    private sanitizer?: DomSanitizer,
    private DCSReportConfigDtlService?: DCSReportConfigDtlService,
    public datepipe?: DatePipe,
    // private dimensionService?: DCSDimensionbaseService | any,
  ) {
    super();
    // this.BaseService = AppConstants.injector.get(BaseService);
    this.lookupConfigs.UserClientConfigs = UserClientConfigs.lister;
  }

  async ngOnInit() {
    // this.dateFormat = this.localStorage.get("defaultDateFormat");
    this.myForm = this.fb.group({});
    var userInfo: any = userInfo = JSON.parse(localStorage.getItem("utuser") || '{}');
    if (userInfo && userInfo.usertypeid && userInfo.usertypeid == GenEnum.UserType.SuperAdmin) {
      this.showConfigButton = true;
    }
    this.activeuser = userInfo
    this.activereport = this.localStorage.get("reportId");
    this.reportname = this.localStorage.get("activeReportScreenname");
    this.activeclientid = this.localStorage.get("clientid");
    this.menutype = Number(this.localStorage.get("menutype"));
    await this.getuserclientcat()

  }
  _reportCode: string = "";
  @Input()
  get reportCode(): string {
    return this._reportCode;
  }

  set reportCode(value) {
    this._reportCode = value;
    if (this._reportCode) {
      this.showloader = true
      this.getReport(value).then(() => {
        this.generateData().then(() => {
          this.setDefaultValues().then(async (x: any) => {
            this.setValues().then((x) => {
              this.showloader = false
              if (this.isAutoGenerated) this.generate();
            });
          });
        });
      });
    }
  }


  async openDocfieldModal() {
    this.FieldConfig = true;
    await this.FieldConfigModal?.open();
  }
  closeDocfieldModal() {
    this.FieldConfigModal?.closeModal();
  }
  async getReport(reportCode: string) {

    const result = await this.reportService.GetReport(reportCode);
    if (result.IsSuccess) {
      this.data = result.Data;
    } else {
      this.showErrors(result.Errors);
    }
  }
  async generateData() {
    this.collapsed = true;
    if (this.data && this.data.utreportdtls) {
      var dtl = new List<reportDetail>(this.data.utreportdtls)
        .OrderBy((o) => o.lineindex)
        .ToArray();
      this.depandentOn = new List<reportDetail>(this.data.utreportdtls)
        .Where((o: any) => o.dependenton ? o.dependenton !== "" && o.dependenton !== undefined && o.dependenton !== null : false)
        .OrderBy((o) => o.lineindex)
        .ToArray();

      var fields: any = {};

      for (let i = 0; i < dtl.length; i++) {
        var element: any = dtl[i];
        fields[element.fieldname] = [];
      }
      this.myForm = this.fb.group(fields);
      for (let index = 0; index < dtl.length; index++) {
        var element: any = dtl[index];
        var field: reportDetail = {
          caption: element.caption,
          fieldname: element.fieldname,
          ismandatory: element.ismandatory,
          isdimension: element.isdimension,
          mode: element.mode,
          fieldtypeid: element.fieldtypeid,
          statusid: element.statusid,
          defaultvalue: element.defaultvalue
            ? element.defaultvalue
            : this.defaultValues[element.fieldname]?.value,
          capwidth: element.capwidth,
          fieldwidth: element.fieldwidth,
          allfield: element.allkey ? element.allkey : false
        };
        if (element.fieldtypeid === ReportingEnum.Lov.FieldType.Combo || element.fieldtypeid === ReportingEnum.Lov.FieldType.MultiCombo || element.fieldtypeid === ReportingEnum.Lov.FieldType.Searchbox) {
          var params: any = [];
          if (!element.dependenton) {
            var indexOf = element.parms ? element.parms.indexOf(",") : -1;
            var paramsSplit: string[] = [];
            if (indexOf !== -1 && element && element.parms) {
              paramsSplit = element.parms.split(",");
            }
            for (let i = 0; i < paramsSplit.length; i++) {
              if (paramsSplit[i] && paramsSplit[i] !== ",")
                params.push(this.myForm?.controls[paramsSplit[i]].value);
            }

            var dropdownData: any = [];
            if (element.fieldtypeid && element.fieldtypeid != ReportingEnum.Lov.FieldType.Searchbox) {

              dropdownData = await this.getData(
                element.fieldname ? element.fieldname : "",
                element.controller ? element.controller : "",
                element.routemethod ? element.routemethod : "",
                params
              );
              if (dropdownData) {

                field.data = dropdownData;
              }
            }
            else {
              dropdownData = [];
              field.data = [];
            }
          }
          // if (element.fieldtypeid === ReportingEnum.Lov.FieldType.Combo && element.allkey && element.allkey === true && element.data && element.data.length > 0){
          //   const alldatas = {[element.valuemember] : "null" , [element.displaymember] : "All", [element]}
          //   const alldata = { "Id": "null", "code": "00", "caption": "All", "ltxt": "All", "entityid": 'null', "indx": 0 }
          // }
          field.valuemember = element.valuemember;
          field.displaymember = element.displaymember;
          field.isdimension = element.isdimension;
          field.mode = element.mode;
          field.params = element.parms;
          field.controller = element.controller;
          field.routemethod = element.routemethod;
          field.utlisterconfighdr = element.ut0listerconfighdr;
          field.allfield = element.allkey;
          if (
            element.utlisterconfighdr &&
            element.utlisterconfighdr.utlisterconfigdtls
          ) {
            element.utlisterconfighdr.utlisterconfigdtls.map((obj: any) => {
              obj.name = obj.fieldname;
              (obj.width = obj.fieldwidth ? obj.fieldwidth + "px" : ""),
                (obj.type = obj.fieldtypeid
                  ? obj.fieldtypeid === ReportingEnum.Lov.FieldType.Date
                    ? "date"
                    : obj.fieldtypeid === ReportingEnum.Lov.FieldType.Time
                      ? "time"
                      : ""
                  : "");
              obj.format = obj.format ? obj.format : "";
            });

            field.utlisterconfigdtls = new List<listerDtl>(
              element.utlisterconfighdr.utlisterconfigdtls
            )
              .OrderBy((o) => o.lineindex)
              .ToArray();
          }
        }


        if (field && field.mode != GenEnum.DimensionMode.Dimension) {
          this.config.push(field);
          this.allconfig.push(field)
        }
        else {
          this.configDimensiondata.push(field)
          this.allconfig.push(field)
        }
      }
      if (this.config && this.config.length > 0) {
        this.directconfig = this.config
      }
      if (this.configDimensiondata && this.configDimensiondata.length > 0) {
        this.directconfigDimensiondata = this.configDimensiondata
      }
    } else {
      this.showErrors("No Report Found!");
    }
  }

  async searchboxclick(element: any) {
    this.showloader = true
    if (this.callhit && this.callhit.length > 0) {
      this.callhit.forEach((evr: any) => {
        if (evr == element.fieldname) {
          element.checked = false
          var ind = this.callhit.findIndex((a: any) => a == element.fieldname);
          this.callhit.splice(ind, 1)
        }
      });
    }
    if (this.depandentOn.length > 0) {
      var depandantsFields = this.depandentOn.filter((e: reportDetail) =>
        e.dependenton ? e.fieldname?.match(element.fieldname) : null
      );
      if (depandantsFields && depandantsFields.length > 0) {

        if (element.checked != true) {
          for (let index = 0; index < depandantsFields.length; index++) {
            const element: reportDetail = depandantsFields[index];
            var params = [];
            var indexOf = element.parms ? element.parms.indexOf(",") : -1;
            var paramsSplit: string[] = [];
            if (indexOf !== -1 && element && element.parms) {
              paramsSplit = element.parms.split(",");
            }
            for (let i = 0; i < paramsSplit.length; i++) {
              if (paramsSplit[i] && paramsSplit[i] !== ",") {
                let data = "";
                if (this.allconfig && this.allconfig.length > 0) {
                  var GetConfig = this.allconfig.filter((a: any) => a.fieldname == paramsSplit[i]);
                  if (GetConfig && GetConfig.length > 0) {
                    if (GetConfig[0].fieldtypeid == 1343) {
                      data = GetConfig[0].innerVal
                        ? GetConfig[0].innerVal
                        : null;
                    }
                    else {
                      data = this.myForm?.controls[paramsSplit[i]]
                        ? this.myForm?.controls[paramsSplit[i]].value
                        : paramsSplit[i];
                    }
                  }
                  else {
                    data = this.myForm?.controls[paramsSplit[i]]
                      ? this.myForm?.controls[paramsSplit[i]].value
                      : paramsSplit[i];
                  }
                }
                params.push(data ? data : "NULL");
              }
            }
            let checkNULL = params.filter((o) => o === "NULL")[0];
            if (!checkNULL) {
              if (element.fieldtypeid == ReportingEnum.Lov.FieldType.Searchbox) {
                var dropdownData = await this.getData(
                  element.fieldname ? element.fieldname : "",
                  element.controller ? element.controller : "",
                  element.routemethod ? element.routemethod : "",
                  params
                );
                if (this.allconfig && this.allconfig.length > 0) {
                  this.allconfig.forEach((ele: any) => {
                    if (ele.fieldname == element.fieldname) {
                      ele.data = dropdownData
                    }
                  });
                }
              }
            }
            else {
              this.showloader = false
              return (this.showErrors("Please fill the dependent field"))
            }
          }
        }
      }
      else {

        var Dt: any = this.allconfig.filter((ele: any) => ele.fieldname == element.fieldname)[0];
        if (Dt.data.length == 0) {
          const element1: reportDetail = element;
          var params = [];
          var indexOf = element1.parms ? element1.parms.indexOf(",") : -1;
          var paramsSplit: string[] = [];
          if (indexOf !== -1 && element && element.parms) {
            paramsSplit = element.parms.split(",");
          }
          for (let i = 0; i < paramsSplit.length; i++) {
            if (paramsSplit[i] && paramsSplit[i] !== ",") {
              let data = "";
              if (this.allconfig && this.allconfig.length > 0) {
                var GetConfig = this.allconfig.filter((a: any) => a.fieldname == paramsSplit[i]);
                if (GetConfig && GetConfig.length > 0) {
                  if (GetConfig[0].fieldtypeid == 1343) {
                    data = GetConfig[0].innerVal
                      ? GetConfig[0].innerVal
                      : null;
                  }
                  else {
                    data = this.myForm?.controls[paramsSplit[i]]
                      ? this.myForm?.controls[paramsSplit[i]].value
                      : paramsSplit[i];
                  }
                }
                else {
                  data = this.myForm?.controls[paramsSplit[i]]
                    ? this.myForm?.controls[paramsSplit[i]].value
                    : paramsSplit[i];
                }
              }
              params.push(data ? data : "NULL");
            }
          }
          let checkNULL = params.filter((o) => o === "NULL")[0];
          if (!checkNULL) {
            if (element.fieldtypeid == ReportingEnum.Lov.FieldType.Searchbox) {
              var dropdownData = await this.getData(
                element.fieldname ? element.fieldname : "",
                element.controller ? element.controller : "",
                element.routemethod ? element.routemethod : "",
                params
              );
              if (this.allconfig && this.allconfig.length > 0) {
                this.allconfig.forEach((ele: any) => {
                  if (ele.fieldname == element.fieldname) {
                    ele.data = dropdownData
                  }
                });
              }
            }
          }
          else {
            this.showloader = false
            return (this.showErrors("Please fill the dependent field"))
          }
        }
      }
    }
    else {
      var dropdownData = await this.getData(
        element.fieldname ? element.fieldname : "",
        element.controller ? element.controller : "",
        element.routemethod ? element.routemethod : "",
        element.params0
      );

      if (this.allconfig && this.allconfig.length > 0) {
        this.allconfig.forEach((ele: any) => {
          if (ele.fieldname == element.fieldname) {
            ele.data = dropdownData
          }
        });
      }

    }
    element.checked = true
    this.showloader = false
    return
  }
  async searchfocus(item: any) {
    if (this.myForm.controls[item.fieldname] && this.myForm.controls[item.fieldname].value != null && this.myForm.controls[item.fieldname].value != undefined && this.myForm.controls[item.fieldname].value != "") {
      var a = this.myForm.controls[item.fieldname].value
      if (a != undefined && a != null) {
        if (item && item.data && item.data.length > 0) {
          var data = item.data.filter((o: any) => o[item.displaymember].toLowerCase() == a.toLowerCase())[0]
          if (data && data != undefined && data != null && data != "") {
            if (this.allconfig && this.allconfig.length > 0) {
              var itemdata = this.allconfig.filter((a: any) => a.fieldname == item.fieldname)[0]
              var valuemem = itemdata.valuemember
              itemdata.innerVal = valuemem ? data[valuemem] : data.Id

              return itemdata.innerVal
              // this.allconfig.forEach((ele: any) => {
              //   if (ele.fieldname == item.fieldname) {
              //     ele.innerVal = data[0].Id;
              //     return
              //   }
              // });
            }
          }
          else {
            this.myForm.controls[item.fieldname].setValue("")
            return this.showErrors("Please type correct" + " " + item.caption);
          }
        }
        else {
          this.myForm.controls[item.fieldname].setValue("")
          return this.showErrors("No Record Found !");
        }
      }
    }
    // return data && data != undefined && data != null && data != "" &&  ?  : "";
  }
  async setDefaultValues() {
    if (this.data && this.data.utreportdtls) {
      var dtl = new List<reportDetail>(this.data.utreportdtls)
        .OrderBy((o) => o.lineindex)
        .ToArray();
      for (let index = 0; index < dtl.length; index++) {
        const element = dtl[index];
        if (element.defaultvalue) {
          let defaultVal = element.defaultvalue;
          if (element.defaultvalue === "{userentity}") {
            defaultVal = this.localStorage.get("selectedEntity");
          }
          if (element.fieldtypeid === ReportingEnum.Lov.FieldType.Date) {
            element.defaultvalue = element.defaultvalue.replace("{", "");
            element.defaultvalue = element.defaultvalue.replace("}", "");
            const defaultValues =
              element.defaultvalue.indexOf(",") !== -1
                ? element.defaultvalue.split(",")
                : [];
            if (defaultValues && defaultValues.length === 3) {
              var date = new Date();
              let type = defaultValues[1];
              if (type === "D") {
                date.setDate(date.getDate() + Number(defaultValues[2]));
              } else if (type === "M") {
                date.setMonth(date.getMonth() + Number(defaultValues[2]));
              } else if (type === "Y") {
                date.setFullYear(date.getFullYear() + Number(defaultValues[2]));
              }
              defaultVal = moment(date).format("YYYY-MM-DD");
            }
          }
          if (element && this.myForm && element.fieldname) {
            if (element.fieldtypeid && element.fieldtypeid == 1278) {
              this.myForm.controls[element.fieldname].setValue(defaultVal);
              await this.valueChangeForMulti(element.fieldname);
            }
            else {
              this.myForm.controls[element.fieldname].setValue(defaultVal);
              await this.valueChange(element.fieldname);
            }

          }
        }
      }
    }
  }

  async getDimension(coaid: any) {

    var params = [];
    params.push(coaid);
    this.customService.controller = "mst_FIDimension";
    const result = await this.customService.get("CoaDimensions", params);
    // const result = await this.customService.get("route", params); await this.reportService.GetCoaDimensions(coaid);

    if (result.IsSuccess) {
      this.LookupData.dimensiondata = result.Data;
    } else {
      this.showErrors(result.Errors);
    }
  }

  async CloseDimModal(event: any) {
    this.configDimension = [];
  }
  async generateDataForDimension() {

    this.configDimension = [];
    // utreportdtls
    this.collapsed = true;
    if (this.LookupData.dimensiondata && this.LookupData.dimensiondata) {
      var dtl = new List<Dimensions>(this.LookupData.dimensiondata)
        .OrderBy((o: any) => o.lineindex)
        .ToArray();

      this.depandentOn = new List<Dimensions>(this.LookupData.dimensiondata)
        .Where((o: any) => o.dependenton ? o.dependenton !== "" && o.dependenton !== undefined && o.dependenton !== null : false)
        .OrderBy((o: any) => o.lineindex)
        .ToArray();

      var fields: any = {};

      for (let i = 0; i < dtl.length; i++) {
        var element: any = dtl[i];
        if (element) {
          this.myForm.controls[element.code] = new FormControl('');
          // this.myForm.controls[element.code].setValue(undefined);
        }
      }

      // this.myForm = this.fb.group(fields);

      for (let index = 0; index < dtl.length; index++) {
        var element: any = dtl[index];

        var field: Dimensions = {
          Id: element.Id,
          stxt: element.stxt + (element.ismandatory ? "*" : ""),
          code: element.code,
          ismandatory: element.ismandatory,
          fieldtypeid: element.fieldtypeid,
          statusid: element.statusid,
          defaultvalue: element.defaultvalue,
          capwidth: "3,3,6,6", //element.capwidth, //,
          fieldwidth: "6,6,12,12",
          paramsSplit: element.parms.split(","),
        };

        //if (element.fieldtypeid === ReportingEnum.Lov.FieldType.Combo) {
        var params: any = [];
        if (!element.dependenton) {
          var indexOf = element.parms ? element.parms.indexOf(",") : -1;

          var paramsSplit: string[] = [];
          if (indexOf !== -1) {
            paramsSplit = element?.parms.split(",");
          }

          for (let i = 0; i < paramsSplit.length; i++) {
            if (paramsSplit[i] && paramsSplit[i] !== ",")
              if (this.myForm.controls[paramsSplit[i]]) {
                params.push(this.myForm.controls[paramsSplit[i]].value);
              } else if (paramsSplit[i] && paramsSplit[i] === "{userentity}") {
                params.push(this.localStorage.get("selectedEntity"));
              } else {
              }
          }

          var dropdownData = await this.getData(
            element.code,
            element.controller,
            element.routemethod,
            params
          );
          if (dropdownData) {
            field.data = dropdownData;
          }
        }

        field.valuemember = element.valuemember;
        field.displaymember = element.displaymember;
        field.params = element.parms;
        field.controller = element.controller;
        field.routemethod = element.routemethod;
        let configData = this.listerConfigs.filter(
          (o) => o.key === element.code
        )[0];
        field.utlisterconfigdtls = configData ? configData.value : [];
        field.utlisterconfighdr = element.utlisterconfighdr;
        if (
          element.utlisterconfighdr &&
          element.utlisterconfighdr.utlisterconfigdtls
        ) {
          element.utlisterconfighdr.utlisterconfigdtls.map((obj: any) => {
            obj.name = obj.fieldname;
            (obj.width = obj.fieldwidth ? obj.fieldwidth + "px" : ""),
              (obj.type = obj.fieldtypeid
                ? obj.fieldtypeid === ReportingEnum.Lov.FieldType.Date
                  ? "date"
                  : obj.fieldtypeid === ReportingEnum.Lov.FieldType.Time
                    ? "time"
                    : ""
                : "");
            obj.format = obj.format ? obj.format : "";
          });

          field.utlisterconfigdtls = new List<listerDtl>(
            element.utlisterconfighdr.utlisterconfigdtls
          )
            .OrderBy((o) => o.lineindex)
            .ToArray();
        }

        this.configDimension.push(field);
      }
      if (this.configDimension && this.configDimension.length > 0) {
        this.dimensionModal.open("md");
      }
      else {
        this.showErrors("There Is No Dimension Available For Selected Account");
      }
    } else {
    }
  }

  public async openDimension(selectedValue: Number) {
    if (selectedValue) {
      await this.getDimension(selectedValue);
      this.generateDataForDimension();
    }
  }
  async setValues() {

    if (this.defaultValues && this.defaultValues.length > 0) {
      this.defaultValues.forEach((obj: any) => {
        if (this.myForm?.controls[obj.key]) {
          this.myForm.controls[obj.key].setValue(obj.value);
        }
      });
    }
  }

  async SaveClose() {
    this.dimensionModal.closeModal();
  }
  async getData(
    fieldname: string,
    controller: string,
    route: string,
    params: Array<any>
  ): Promise<Array<any>> {
    let returnData: any = [];
    this.customService.controller = controller;
    var result;
    if (this.isAutoGenerated == false) {
      result = await this.customService.get(route, params);
    }
    if (result && result.IsSuccess || this.isAutoGenerated == true) {
      var apidata = result && result.Data ? result?.Data : [];
      returnData = apidata
      if (!this.LookupData[fieldname] || !this.isEqualArray(this.LookupData[fieldname], apidata)) {
        this.LookupData[fieldname] = apidata;
        let value = this.defaultValues.filter((o) => o.key === fieldname)[0];
        if (
          value &&
          this.myForm && this.myForm.controls[fieldname] &&
          !this.myForm.controls[fieldname].value
        ) {
          if (this.myForm) {
            this.myForm.controls[fieldname].setValue(value.value);

            await this.valueChange(fieldname);
          }
        }
      } else if (result) {
        this.showErrors(result.Errors);
      }
    }

    return returnData;
  }

  async valueChange(fieldname: string) {
    if (this.depandentOn.length > 0) {
      var depandantsFields = this.depandentOn.filter((e: reportDetail) =>
        e.dependenton ? e.dependenton.match(fieldname) : null
      );
      if (depandantsFields && depandantsFields.length > 0) {
        for (let index = 0; index < depandantsFields.length; index++) {
          const element: reportDetail = depandantsFields[index];
          var params = [];
          var indexOf = element.parms ? element.parms.indexOf(",") : -1;
          var paramsSplit: string[] = [];
          if (indexOf !== -1 && element && element.parms) {
            paramsSplit = element.parms.split(",");
          }
          for (let i = 0; i < paramsSplit.length; i++) {
            if (paramsSplit[i] && paramsSplit[i] !== ",") {
              let data = this.myForm?.controls[paramsSplit[i]]
                ? this.myForm?.controls[paramsSplit[i]].value
                : paramsSplit[i];
              params.push(data ? data : "NULL");
            }
          }
          let checkNULL = params.filter((o) => o === "NULL")[0];
          if (checkNULL == "NULL") {
            var fielddata = this.directconfig.filter((a: any) => a.fieldname == fieldname)[0]
            if (fielddata && fielddata.allfield && fielddata.allfield == true) {
              checkNULL = undefined
            }
          }
          if (!checkNULL) {
            if (element.fieldtypeid != ReportingEnum.Lov.FieldType.Searchbox) {
              await this.getData(
                element.fieldname ? element.fieldname : "",
                element.controller ? element.controller : "",
                element.routemethod ? element.routemethod : "",
                params
              );
            }
            else {
              this.callhit = []
              this.callhit.push(element.fieldname)
            }
          }
        }
      }
    }
  }


  async valueChangeForMulti(fieldname: string) {

    if (this.depandentOn.length > 0) {
      var depandantsFields = this.depandentOn.filter((e: reportDetail) =>
        e.dependenton ? e.dependenton.match(fieldname) : null
      );
      if (depandantsFields && depandantsFields.length > 0) {
        for (let index = 0; index < depandantsFields.length; index++) {
          const element: reportDetail = depandantsFields[index];
          var params = [];
          var indexOf = element.parms ? element.parms.indexOf(",") : -1;
          var paramsSplit: string[] = [];
          if (indexOf !== -1 && element && element.parms) {
            paramsSplit = element.parms.split(",");
          }
          for (let i = 0; i < paramsSplit.length; i++) {
            if (paramsSplit[i] && paramsSplit[i] !== ",") {
              let data = this.myForm?.controls[paramsSplit[i]]
                ? this.myForm?.controls[paramsSplit[i]].value
                : paramsSplit[i];
              if (data && data.length >= 0) {
                data.forEach((dt: any) => {
                  params.push(dt.item_id ? dt.item_id : "NULL");
                });
              }
              else {
                params.push(data ? data : "NULL");
              }
            }
          }
          let checkNULL = params.filter((o) => o === "NULL")[0];
          if (!checkNULL) {
            await this.getData(
              element.fieldname ? element.fieldname : "",
              element.controller ? element.controller : "",
              element.routemethod ? element.routemethod : "",
              params
            );
          }
        }
      }
    }
  }
  private validate(): boolean {
    let isDirty: boolean = false;
    let ctrl: any;
    let mandatoryFields: any = [];
    if (this.myForm)
      Object.keys(this.myForm?.controls).map((controlName) => {
        ctrl = this.myForm?.get(controlName);
        ctrl.markAsDirty({ onlySelf: true });
        ctrl.markAsTouched({ onlySelf: true });
        if (!isDirty) {

          let ctrlConfig = this.allconfig.filter(
            (o) => o.fieldname === controlName
          )[0];
          isDirty = ctrl.errors !== null;
          if (
            (isDirty && !this.myForm?.controls[controlName].value) ||
            (ctrlConfig && ctrlConfig.ismandatory &&
              this.myForm?.controls[controlName].value === 0 && ctrlConfig.allfield && ctrlConfig.allfield != true)
          ) {
            isDirty = true;
            mandatoryFields.push({
              Description:
                (ctrlConfig ? ctrlConfig.caption : controlName) +
                " cannot be empty",
            });
          }
        }
      });
    if (mandatoryFields.length > 0) {
      this.showErrors(mandatoryFields);
    }
    return isDirty;
  }

  private setReportValues() {
    Object.keys(this.myForm?.value).forEach((name) => {
      this.myForm?.controls[name].setValue(undefined);
    });
  }

  async clear() {
    let ctrl: any;
    if (this.myForm)
      Object.keys(this.myForm.controls).map((controlName: string) => {
        if (controlName) {
          ctrl = this.myForm?.get(controlName);
          if (ctrl) {
            ctrl.markAsDirty({ onlySelf: true });
            ctrl.markAsTouched({ onlySelf: true });
          }
        }
      });

    this.setReportValues();
    await this.setDefaultValues();

    this.depandentOn.forEach((element: reportDetail) => {
      if (element.fieldname) delete this.LookupData[element.fieldname];
    });
    this.url = undefined;
    this.isCollapsed = false;
  }

  generate() {

    setTimeout(async () => {
      //   var statuscheck = 0

      if (!this.validate()) {
        this.showloader = true
        this.showReportPanel = true;
        this.mapValues();
        var routeUrl: string = this.data.routeurl;
        this.hidereportTab = false;

        let routeSplit = this.data.routeurl.split("/{");

        if (routeSplit && routeSplit.length > 0) {
          for (let index = 0; index < routeSplit.length; index++) {
            if (routeSplit[index].indexOf("}") !== -1) {
              let ctrlName = routeSplit[index].replace("}", "");
              let val: any = "null";
              var Seldata = this.allconfig.filter((a: any) => a.fieldname == ctrlName)[0];

              if (Seldata && Seldata.fieldtypeid && Seldata.fieldtypeid == 1096) {

                val =
                  this.myForm?.controls[ctrlName] &&
                    this.myForm?.controls[ctrlName].value
                    ? this.datepipe?.transform(this.myForm?.controls[ctrlName].value, 'yyyy-MM-dd')
                    : "null";
              }
              else {
                if (Seldata && Seldata.fieldtypeid && Seldata.fieldtypeid == 1343) {

                  if (this.myForm.controls[ctrlName] && this.myForm.controls[ctrlName].value) {

                    var geted = this.searchfocus(Seldata);
                    var valdat = this.allconfig.filter((z: any) => z.fieldname == Seldata.fieldname)[0]
                    // var selectdata = this.myForm.controls[ctrlName].value
                    // var d = Seldata.displaymember
                    // var dataset1 = Seldata.data?.filter((o: any) => o[Seldata.displaymember].toLowerCase() == selectdata.toLowerCase())[0]
                    // if (dataset1 && dataset1.Id) {

                    val = valdat && valdat.innerVal ? valdat.innerVal : "null";
                    // }
                    // else {
                    //   this.showErrors("Please type correct" + " " + Seldata.caption);
                    //   selectdata = undefined
                    //   statuscheck = 1
                    //   break

                    // }
                  }
                }
                else {
                  val =
                    this.myForm?.controls[ctrlName] &&
                      this.myForm?.controls[ctrlName].value
                      ? this.myForm?.controls[ctrlName].value
                      : "null";
                }
              }
              if (Seldata && Seldata.fieldtypeid && Seldata.fieldtypeid == 1278) {
                var separator = "";
                var getter: any = [];
                if (val != "null" && val != null) {
                  if (val && val.length > 0) {
                    val.forEach((el: any) => {
                      getter.push(el.item_id);
                    });
                    if (getter && getter.length > 0) {
                      separator = getter.join(",");
                    }
                    routeUrl = routeUrl.replace(
                      "{" + ctrlName + "}",
                      separator ? separator : "null"
                    );
                  }
                  else {
                    routeUrl = routeUrl.replace(
                      "{" + ctrlName + "}",
                      separator ? separator : "null"
                    );
                  }
                }
                else {
                  routeUrl = routeUrl.replace(
                    "{" + ctrlName + "}",
                    separator ? separator : "null"
                  );
                }
              }
              else {
                routeUrl = routeUrl.replace(
                  "{" + ctrlName + "}",
                  val ? val : "null"
                );
              }
            }
          }
        }

        var subReports = [];
        if (this.data && this.data.utsubreports) {
          for (let index = 0; index < this.data.utsubreports.length; index++) {
            const subReport = this.data.utsubreports[index];

            if (subReport && subReport.reportname && subReport.routeurl) {
              let routeSplit = subReport.routeurl.split("/{");
              var subRouteUrl = subReport.routeurl;

              if (routeSplit && routeSplit.length > 0) {
                for (let index1 = 0; index1 < routeSplit.length; index1++) {
                  if (routeSplit[index1].indexOf("}") !== -1) {
                    let ctrlName = routeSplit[index1].replace("}", "");

                    let val =
                      this.myForm?.controls[ctrlName] &&
                        this.myForm?.controls[ctrlName].value
                        ? this.myForm?.controls[ctrlName].value
                        : "null";

                    subRouteUrl = subRouteUrl.replace(
                      "{" + ctrlName + "}",
                      val ? val : "null"
                    );
                  }
                }
                subReports.push({
                  reportname: subReport.reportname,
                  routeurl: subRouteUrl,
                });
              }
            }
          }
        }

        //////////////////////////////////dimention fields data////////////////////////////
        let valdimention: any = "null";
        this.dimenstiontextarea = ""
        if (this.configDimensiondata && this.configDimensiondata.length && this.configDimensiondata.length > 0) {

          if (routeSplit && routeSplit.length > 0) {
            for (let index1 = 0; index1 < routeSplit.length; index1++) {
              if (routeSplit[index1].indexOf("}") !== -1) {
                let ctrlName = routeSplit[index1].replace("}", "");
                var dimendata = this.configDimensiondata.filter((b: any) => b.fieldname == ctrlName)[0]
                if (dimendata && dimendata.mode && dimendata.mode == 1333) {
                  if (dimendata && dimendata.mode && dimendata.fieldtypeid == ReportingEnum.Lov.FieldType.Combo) {
                    valdimention =
                      this.myForm?.controls[ctrlName] &&
                        this.myForm?.controls[ctrlName].value
                        ? this.myForm?.controls[ctrlName].value
                        : "null";
                    if (valdimention != "null") {

                      var cap = dimendata.caption
                      var valumem = dimendata.valuemember
                      var displymem = dimendata.displaymember
                      var lookups = this.LookupData[ctrlName]
                      var finallookup = lookups.filter((c: any) => valumem ? c[valumem] == valdimention : c.Id == valdimention)[0]
                      if (finallookup != undefined) {
                        var disp = displymem ? finallookup[displymem] : finallookup.stxt
                        var totaldisp = cap + ": " + disp + '>@@'
                        this.dimenstiontextarea = this.dimenstiontextarea + totaldisp
                      }
                    }
                  }
                  if (dimendata && dimendata.mode && dimendata.fieldtypeid == ReportingEnum.Lov.FieldType.Searchbox) {
                    valdimention =
                      this.myForm?.controls[ctrlName] &&
                        this.myForm?.controls[ctrlName].value
                        ? this.myForm?.controls[ctrlName].value
                        : "null";
                    if (valdimention != "null") {
                      var cap = dimendata.caption
                      var disp = valdimention
                      var totaldisp = cap + ": " + disp + '>@@'
                      this.dimenstiontextarea = this.dimenstiontextarea + totaldisp
                    }
                  }
                }
              }
            }

          }
          if (this.dimenstiontextarea == undefined || this.dimenstiontextarea == "") {
            this.dimenstiontextarea = "null"
          }
        }
        else {
          this.dimenstiontextarea = "null"
        }

        //////////////////////////////////dimention fields data end////////////////////////////



        this.connectConfig = JSON.parse(this.localStorage.get('connectConfig'))
        // if (statuscheck == 0) {
        let entityid =
          this.myForm?.controls["entityid"] &&
            this.myForm?.controls["entityid"].value
            ? this.myForm?.controls["entityid"].value
            : this.localStorage.get("selectedEntity");
        this.url =
        this.connectConfig.urls.reportUrl +
          "?" +
          this.data.reportname +
          "&&" +
          routeUrl +
          "&&" +
          entityid +
          "&&" +
          this.data.stxt +
          "&&" +
          this.localStorage.get("token").replace("bearer ", "") +
          "&&" +
          this.dimenstiontextarea +
          "&&" +
          JSON.stringify(subReports);
        if (this.sanitizer) {
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        }
        // -----------Tab DATA WORK START-----------
        this.isCollapsed = true;
        if (this.data && this.data.reportname && this.data.reportname == "_") {
          this.hidereportTab = true;
          this.showloader = true;
          this.LookupData.reportdatalist = []
          this.dataclickcheck = true
          await this.activeTabs();
          var datasplit = routeUrl.split("/")
          var rptname = datasplit[1]
          var webpgurl = this.localStorage.get("apiUrl");
          var splitapi = webpgurl.replace("api/", "")
          var mainurl = splitapi + routeUrl
          this.mainurls = mainurl
          this.BaseService = new BaseService<any>(rptname, routeUrl);
          const dto = await this.BaseService.getreportAll(mainurl);
          if (dto && dto.Data) {
            this.LookupData.reportdatalist = dto.Data
            this.LookupData.reportdatalistshow = dto.Data
            if (this.LookupData.reportdatalist && this.LookupData.reportdatalist.length > 0) {
              this.isCollapsed = true;
              this.showReportPanel = true;
            }
            else {
              this.isCollapsed = false;
              this.showReportPanel = false;
            }

          }
          if (this.menutype && this.menutype === 3) {
            await this.getreportconfig(this.activereport, this.activeuser.userid)
            await this.mydata()
          }
          this.showloader = false;
        }
        // }
      } else {
      }
    }, 4)
  }
  // ================================ DATA TAB GRID GET DATA API  START===================================
  async getreportconfig(reportid: any, userid: any) {
    this.LookupData.clientreportdata = []
    this.LookupData.userreportdata = []
    this.LookupData.reportdata = []
    const dto: any = await this.DCSReportConfigDtlService?.getreportconfig(reportid, userid);
    if (dto && dto.IsSuccess) {
      this.LookupData.userreportdata = dto.Data;
      await this.getreportclientwise(reportid)
    }
    else {
      this.showErrors(dto.Errors);
    }
  }
  async getreportclientwise(reportid: any) {

    const dto: any = await this.DCSReportConfigDtlService?.getreportconfig(reportid, 'null');
    if (dto && dto.IsSuccess) {
      this.LookupData.clientreportdata = dto.Data;
      if (this.LookupData.userreportdata && this.LookupData.userreportdata.length > 0) {
        this.LookupData.reportdata = this.LookupData.userreportdata
      }
      else {
        this.LookupData.reportdata = this.LookupData.clientreportdata
      }
    }
    else {
      this.showErrors(dto.Errors);
    }
  }
  // ================================ DATA TAB GRID GET DATA API  END================================
  dataclickcheck: boolean = false
  async tabClick(event: any) {
    if (event && event.title === "Data") {
      if (this.dataclickcheck == true) {
        // const dto = await this.BaseService?.getreportAll(this.mainurls);
        // if (dto && dto.Data) {
        //   this.LookupData.reportdatalist = dto.Data
        // }
        if (this.menutype && this.menutype === 3) {
          await this.getreportconfig(this.activereport, this.activeuser.userid)
          await this.mydata()
        }
        this.dataclickcheck = false
      }
    }
  }
  async activeTabs() {
    if (this.tabs1 && this.tabs1.tabs) {
      this.tabs1.tabs.forEach((element: any) => {
        return element.active = false
      });
      if (this.tabs1 && this.tabs1.tabs && this.tabs1.tabs.first)
        this.tabs1.tabs.first.active = true
    }
  }
  // ================================ DATA TAB GRID START===================================
  async mydata() {
    if (this.LookupData.reportdatalist && this.LookupData.reportdatalist.length > 0) {
      this.LookupData.modaldata = []
      var headername = Object.keys(this.LookupData.reportdatalist[0])
      this.columnDefs = [];
      headername.forEach((element: any) => {
        if (element != "lineindex") {
          const newcolmns = {
            headerName: element,
            field: element,
            width: 155,
            indexings: null,
          }
          this.columnDefs.push(newcolmns);
        }
      })
      await this.checkingpur()
    }
  }
  async checkingpur() {
    setTimeout(() => {
      if (this.LookupData.reportdata && this.LookupData.reportdata.length > 0) {
        this.columnDefs.forEach((e: any) => {
          var data = this.LookupData.reportdata.filter((a: any) => a.fieldid == e.field);
          if (data && data.length > 0) {
            e.headerName = data && data[0].caption ? data[0].caption : e.headerName;
            e.indexings = data && data[0].indexing;
          }
          else {
            this.agGrid?.options.columnApi.setColumnVisible(e.field, false)
          }

        });
        var ShowCol = this.columnDefs.sort((a: any, b: any) => a.indexings - b.indexings).filter((a: any) => a.indexings > 0);
        var ShowNoneCol = this.columnDefs.filter((a: any) => a.indexings == null);
        var MakeColumns: any = [];
        ShowCol.forEach((e: any) => {
          MakeColumns.push(e);
        });
        ShowNoneCol.forEach((e: any) => {
          MakeColumns.push(e);
        });

        this.columnDefs = MakeColumns;
        //  this.columnDefs.sort((a: any, b: any) => (a.indexings > 0 ? a.indexings - b.indexings : 0));
      }
      this.agGrid.options.api.refreshCells({ force: true });
      this.agGrid.options.api.refreshView();
    }, 0);
  }
  // ================================ DATA TAB GRID END===================================
  //=======================SAVE TEMPLATE MODAL OPEN DROPDOWN DATA START========================
  async getuserclientcat() {
    const dto = await this._lovService.getuserclientcat()
    if (dto && dto.IsSuccess) {
      this.LookupData.userclientcatgory = dto.Data;
      if (this.LookupData.userclientcatgory && this.LookupData.userclientcatgory.length > 0) {
        this.selectedlovstatusid = this.LookupData.userclientcatgory[0].Id
      }
    }
    else {
      this.showErrors(dto.Errors);
    }
  }
  //=======================SAVE TEMPLATE MODAL OPEN DROPDOWN DATA END========================
  //=======================SAVE TEMPLATE MODAL OPEN START========================
  async toolbarclickopenmodale(item: customButton) {
    if (item.name === "btnSave") {
      this.modaldata = [];
      var maingriddata = this.agGrid.options.api.columnController.getAllGridColumns()
      maingriddata.forEach((key: any) => {
        if (key.visible == true) {
          this.modaldata.push(key);
        }
      });
      if (this.modaldata && this.modaldata.length > 0) {
        this.modalgriddata = []
        this.modaldata.forEach((ele: any) => {
          if (ele.colId != "lineindex") {
            this.modalgriddata.push({
              field: ele.colId,
              caption: ele.colDef.headerName
            })
          }
        });
        var userInfo: any = userInfo = JSON.parse(localStorage.getItem("utuser") || '{}');
        if (userInfo && userInfo.usertypeid && userInfo.usertypeid != GenEnum.UserType.SuperAdmin || userInfo.usertypeid != GenEnum.UserType.AdminUser) {
          // this.showConfigButton = true;
          if (this.LookupData.userclientcatgory && this.LookupData.userclientcatgory.length > 0) {
            var dataset = this.LookupData.userclientcatgory.filter((a: any) => a.Id != 1383)
            this.LookupData.userclientcatgory = dataset
          }
        }
        this.openmodal.open();
        this.CustomButtonEntitty();
        await this.gridConfigalegact();
      }
      else {
        this.showErrors("No Record Found.")
      }
      // this.openmodal.closeModal();
    }
  }
  //=======================SAVE TEMPLATE MODAL OPEN END========================
  public Sharedbutton = <customButton[]>[
    {
      name: "btnSave",
      caption: "Save Template",
      icon: "fa fa-gears",
    },
  ];
  // ========================== SAVE TEMPLATE MODAL DATA SAVE START=========================
  async rptconfigsave(item: customButton) {
    if (item.name === "btnSave") {
      const result: any = {};
      const Data: any = {};
      if (this.selectedlovstatusid != null && this.selectedlovstatusid != undefined && this.selectedlovstatusid != "") {
        if (this.selectedlovstatusid == 1382) {
          if (this.LookupData.userreportdata && this.LookupData.userreportdata.length > 0) {
            for (var i = 0; i < this.LookupData.userreportdata.length; i++) {
              var dele = this.LookupData.userreportdata[i]
              await this.DCSReportConfigDtlService?.delete(dele.Id);
            }
          }
        }
        else {
          if (this.LookupData.clientreportdata && this.LookupData.clientreportdata.length > 0) {
            for (var i = 0; i < this.LookupData.clientreportdata.length; i++) {
              var dele = this.LookupData.clientreportdata[i]
              await this.DCSReportConfigDtlService?.delete(dele.Id);
            }
          }
        }
        if (this.configgrid && this.configgrid.data && this.configgrid.data.length > 0) {
          var i = 0
          this.configgrid.data.forEach(async (ele: any) => {
            Data.fieldid = ele.field
            Data.indexing = ++i
            Data.caption = ele.caption != undefined ? ele.caption : ele.field
            Data.reportid = this.activereport
            Data.clientid = this.activeclientid
            Data.userid = this.selectedlovstatusid == 1382 ? this.activeuser.userid : 'null'
            result.Data = Data;
            await this.DCSReportConfigDtlService?.save(result).then(async (o) => { });
          });
          // this.showSuccess("Record Successfully Saved...");
          // await this.getreportconfig(this.activereport, Data.userid)
          // await this.mydata()

        }
        this.openmodal.closeModal();
        this.dataclickcheck = true
        // this.LookupData.reportdatalist = []
        // const dto = await this.BaseService?.getreportAll(this.mainurls);
        // if (dto && dto.Data) {
        //   this.LookupData.reportdatalist = dto.Data
        // }
        setTimeout(async () => {
          await this.activeTabs()
        }, 2);

      }
      else {
        this.showErrors("Please select the Shared first")
      }
    }
    if (item.name == "lovstatusid") {
      if (item.value) {
        this.selectedlovstatusid = item.value
      }
    }
  }
  // ========================== SAVE TEMPLATE MODAL DATA SAVE END=========================
  CustomButtonEntitty() {
    this.customButtonEntity = <customButton[]>[
      {
        name: "btnSave",
        caption: "Save",
        icon: "fa fa-save",
      },
      {
        name: "lovstatusid",
        caption: "Shared",
        type: CustomButtonType.Dropdown,
        valueMember: 'Id',
        displayMember: 'stxt',
        dropDownData: this.LookupData.userclientcatgory,
        config: this.lookupConfigs.UserClientConfigs,
        value: this.selectedlovstatusid
      },
    ]
  }
  async gridConfigalegact() {
    this.columnListDefslegact = [
      {
        headerName: 'Id',
        field: 'Id',
        width: 70,
        hide: true,
      },
      {
        headerName: 'Fields',
        field: 'field',
        width: 150,
        editable: false,
      },
      {
        headerName: 'Caption',
        field: 'caption',
        width: 170,
        editable: true,
      },
    ]
  }
  // -----------------Tab DATA WORK END----------------------
  async receiveCrossFunc(event: any) {
    //console.log(event);
    if (this.iframe != undefined) {
      this.showloader = false
    }
    var routeUrl = this.data.routeurl;
    this.LookupData.reportdatalist = []
    this.dataclickcheck = true
    await this.activeTabs();
    var datasplit = routeUrl.split("/")
    var rptname = datasplit[1]
    var webpgurl = this.localStorage.get("apiUrl");
    var splitapi = webpgurl.replace("api/", "")
    var mainurl = splitapi + routeUrl
    this.mainurls = mainurl
    this.BaseService = new BaseService<any>(rptname, routeUrl);
    //const dto = await this.BaseService.getreportAll(mainurl);

    //console.log(event.data);
    const dto = JSON.parse(event.data);
    if (dto && dto.Data) {
      this.LookupData.reportdatalist = dto.Data
      this.LookupData.reportdatalistshow = dto.Data
      if (this.LookupData.reportdatalist && this.LookupData.reportdatalist.length > 0) {
        this.isCollapsed = true;
        this.showReportPanel = true;
      }
      else {
        this.isCollapsed = false;
        this.showReportPanel = false;
      }
    }

    if (this.menutype && this.menutype === 3) {
      await this.getreportconfig(this.activereport, this.activeuser.userid)
      await this.mydata()
    }
  }
  async loadEnd() {
    document.getElementsByTagName('iframe')[0].contentWindow?.postMessage(JSON.stringify({
      key: "rptData",
      method: "get"
    }), "*");

  }

  mapValues() {
    if (this.myForm && this.data.utreportdtls) {
      var dtl = new List<reportDetail>(this.data.utreportdtls)
        .OrderBy((o) => o.lineindex)
        .ToArray();

      for (let index = 0; index < dtl.length; index++) {
        let field = this.data.utreportdtls[index];

        const element = this.myForm?.controls[field.fieldname];
        if (element && !element.value) {
          let value = this.defaultValues.filter(
            (o) => o.key === field.fieldname
          )[0];
          if (value) element.setValue(value.value);
        }
      }
    }
  }

  Collapsed() {

    this.isCollapsed = !this.isCollapsed || false;
  }

  isEqualArray(itemA: Array<any>, itemB: Array<any>): boolean {

    if (itemA && itemA.length === 0)
      return false;

    for (var i = 0; i < itemA.length; i++)
      if (itemA[i] != itemB[i])
        return false;
    return true;
  }
}

interface reportDetail {
  caption: string;
  controller?: string;
  routemethod?: string;
  parms?: string;
  valuemember?: string;
  displaymember?: string;
  data?: Array<any>;
  defaultvalue?: any;
  fieldname?: string;
  ismandatory?: boolean;
  isdimension?: boolean;
  mode?: any;
  lineindex?: number;
  fieldtypeid?: number;
  statusid?: number;
  dependenton?: string;
  params?: string;
  utlisterconfighdr?: { utlisterconfigdtls?: Array<any> };
  utlisterconfigdtls?: Array<any>;
  capwidth?: string;
  fieldwidth?: string;
  innerVal?: any;
  allfield?: boolean;
}

const ReportingEnum = {
  Lov: {
    FieldType: {
      Text: 1095,
      Date: 1096,
      Combo: 1097,
      MultiCombo: 1278,
      Numeric: 1098,
      Checkbox: 1099,
      Time: 1105,
      Searchbox: 1343,

    },

    ControlStatus: {
      Enable: 1100,
      Disable: 1101,
      Hide: 1102,
    },
  },
};

interface listerConfig {
  key: string;
  value: Array<any>;
}

interface Dimensions {
  Id: number;
  stxt: string;
  controller?: string;
  routemethod?: string;
  parms?: string;
  valuemember?: string;
  displaymember?: string;
  data?: Array<any>;
  defaultvalue?: any;
  code?: string;
  ismandatory?: boolean;
  lineindex?: number;
  fieldtypeid?: number;
  statusid?: number;
  dependenton?: string;
  params?: string;
  utlisterconfighdr?: { utlisterconfigdtls?: Array<any> };
  utlisterconfigdtls?: Array<any>;
  capwidth?: string;
  fieldwidth?: string;
  paramsSplit: any;
}

interface listerDtl {
  lineindex?: number;
  fieldname?: string;
  name?: string;
  caption: string;
  fieldwidth?: number;
  fieldtypeid?: number;
  format?: string;
  type?: string;
  width?: string;
}
